// @flow
import * as React from "react";
import Image from "react-bootstrap/Image";
import emailimg from "../static/images/svg-icon/email.svg";
import localimg from "../static/images/svg-icon/location.svg";
import phoneimg from "../static/images/svg-icon/phone.svg";
import instaicon from "../static/images/svg-icon/instaicon.svg";
import facebookicon from "../static/images/svg-icon/facebookicon.svg";
import newlogo from "../static/images/newlogo.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer-bg">
      <div className="container py-4">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-lg-4  col-xl-3">
            <Image
              src={newlogo}
              rounded
              className="img-fluid my-2"
              style={{ width: "250px" }}
            />

            <div>
              <h2 className="text-white">Contact Us</h2>

              <div className="d-flex align-items-center gap-3 my-2">
                <div>
                  <Image
                    src={phoneimg}
                    rounded
                    className="img-fluid my-2"
                    style={{}}
                  />
                </div>
                <div className="text-white">+91 85058 30505</div>
              </div>

              <div className="d-flex align-items-center gap-3 my-2">
                <div>
                  <Image
                    src={emailimg}
                    rounded
                    className="img-fluid my-2"
                    style={{}}
                  />
                </div>
                <div className="text-white">anhadimmigration@gmail.com</div>
              </div>

              <div className="d-flex align-items-center gap-3 my-2">
                <div>
                  <Image
                    src={localimg}
                    rounded
                    className="img-fluid my-2"
                    style={{}}
                  />
                </div>
                <div className="text-white">
                  D-314, Defence Colony Delhi 110024.
                </div>
              </div>

              <hr style={{ color: "white", opacity: "opacity" }} />

              <div className="d-flex align-items-center gap-4 my-2">
                <a
                  href="https://www.instagram.com/anhad_immigration/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={instaicon}
                    rounded
                    className="img-fluid my-2"
                    style={{ width: "30px" }}
                    alt="Instagram"
                  />
                </a>

                <a
                  href="https://www.facebook.com/anhadimmigration/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={facebookicon}
                    rounded
                    className="img-fluid my-2"
                    style={{ width: "30px" }}
                    alt="Facebook"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7 col-xl-8 d-flex flex-column justify-content-end">
            <div className="d-flex justify-content-end">
              <hr
                style={{
                  width: "168px",
                  color: "#F0F0F0",
                  border: "1px solid #F0F0F0",
                  opacity: "1",
                }}
              />
            </div>

            <div className="d-flex justify-content-end">
              <hr
                style={{
                  width: "233px",
                  color: "#F0F0F0",
                  border: "1px solid #F0F0F0",
                  opacity: "1",
                }}
              />
            </div>

            <div className="d-flex justify-content-end">
              <hr
                style={{
                  width: "360px",
                  color: "#F0F0F0",
                  border: "1px solid #F0F0F0",
                  opacity: "1",
                }}
              />
            </div>

            <div className="">
              <div className="my-2 my-md-5 text-white h3 fw-300">
                Quick Links
              </div>

              <ul className="fw-300 flex-column flex-md-row text-decoration-none  list-unstyled d-flex justify-content-between">
                <li className="my-2 my-md-0">
                  {" "}
                  <Link to="/" className="text-white text-decoration-none">
                    Home
                  </Link>
                </li>
                <li className="my-2 my-md-0">
                  {" "}
                  <Link
                    to="/about-us"
                    className="text-white text-decoration-none"
                  >
                    About Us
                  </Link>
                </li>
                <li className="my-2 my-md-0">
                  {" "}
                  <Link
                    to="/study-in-canada"
                    className="text-white text-decoration-none"
                  >
                    Study
                  </Link>
                </li>
                {/* <li className="my-2 my-md-0">
                  {" "}
                  <Link
                    to="/work-in-australia"
                    className="text-white text-decoration-none"
                  >
                    Work in Australia
                  </Link>
                </li> */}
                <li className="my-2 my-md-0">
                  {" "}
                  <Link
                    to="/contact-us"
                    className="text-white text-decoration-none"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="my-2 my-md-0">
                  {" "}
                  <Link
                    to="/student-loan"
                    className="text-white text-decoration-none"
                  >
                    Student Loan
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="d-flex
    flex-column
    flex-md-row
    justify-content-between
    align-items-center
    my-4
    "
          >
            <div className="text-white">
              © 2023 Anand Immigration. All Rights Reserved.
            </div>
            {/* <div className="text-white">
              Designed & Developed by Fart Magzine
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};
