import { useState } from "react";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
import Image from "react-bootstrap/Image";
import emailimg from "../static/images/svg-icon/email.svg";
import localimg from "../static/images/svg-icon/location.svg";
import phoneimg from "../static/images/svg-icon/phone.svg";

export function Contact() {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Message: "",
  });

  const [message, setMessage] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://anhad.pythonanywhere.com/api/student-contact-form/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        setMessage('Form data submitted successfully')
       
      } else {
        setMessage('Form data submission failed');
        // Handle errors if needed
      }
    } catch (error) {
      console.error('An error occurred during form submission:', error);
      // Handle errors if needed
    }
  };
  

  // const [formData2, setFormData2] = useState({
  //   Name: "",
  //   Email: "",
  //   Message: "",
  // });

  // const handleChange2 = (e) => {
  //   const { name, value } = e.target;
  //   setFormData2({
  //     ...formData2,
  //     [name]: value,
  //   });
  // };


  // const handleSubmit2 = (e) => {
  //   e.preventDefault();
  //   // Handle the second form submission here if needed
  //   console.log("Form 2 data submitted:", formData2);
  // };


  const mycard = {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    gap: '1rem',
    background: '#10788F',
    padding: '40px 30px 40px 30px',
    borderRadius: '8px',
    maxWidth: '300px',
    justifyContent: 'space-evenly',
  };



  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row py-5">
          <div className="col-sm-6">
            <div className="display-5 fw-400 red mb-3">Get In Touch</div>
            <i className="" style={{ color: "#10788F" }}>
                "Embark on your educational journey with Anhad Immigration Group – your gateway to academic success in the UK, Canada, and the USA. Get in touch with us for expert advisory and consultancy services that go beyond conventional boundaries. Fulfill your dreams of studying in renowned international colleges with our dedicated support. Join our mentorship program across three transformative phases, designed to guide you to excellence. Your aspirations, our commitment – let's shape your future together."
            </i>
          </div>
          <div className="col-sm-6 bg-light p-4 rounded-3">
            <form onSubmit={handleSubmit} className="">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary mt-2">
                Submit
              </button>
            </form>
            <h6 className="" style={{color:"green"}}>{message}</h6>
          </div>
        </div>
      </div>

    <div className="container">
        <div className="row my-3">
          <div className="col-12 d-flex flex-row flex-wrap justify-content-center justify-content-md-between">
            <div className="my-4">
            <div style={{ ...mycard, padding: '51px 40px' }}>
                    <Image
                        src={emailimg}
                        rounded
                        className="img-fluid my-2"
                        style={{width:'40px'}}
                      />
                    <div className="d-flex flex-column text-center">
                      <h4 className="text-white fw-bold">Email</h4>
                      <div className="text-white">anhadimmigration@gmail.com</div>
                    </div>
                </div>


                

            </div>
            <div className="my-4">
            <div style={{ ...mycard, padding: '51px 40px' }}>

            <Image
                    src={phoneimg}
                    rounded
                    className="img-fluid my-2"
                    style={{width:'40px'}}
                  />
                    <div className="d-flex flex-column text-center px-4">
                      <h4 className="text-white fw-bold">Phone</h4>
                      <div className="text-white">+91 85058 30505</div>
                    </div>
                </div>


            </div>
            <div className="my-4">
 
            <div style={mycard}>
            <Image
                    src={localimg}
                    rounded
                    className="img-fluid my-2"
                    style={{width:'33px'}}
                  />
                   
                    <div className="d-flex flex-column text-center">
                      <h4 className="text-white fw-bold">Address</h4>
                      <div className="text-white">D-314, Defence Colony Delhi 110024.</div>
                    </div>
                </div>
  
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-6 col-lg-5 col-xl-4">
            <i className="" style={{ color: "#10788F" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima
              blanditiis iste excepturi placeat possimus dolor quos facere
              asperiores recusandae culpa eius voluptatibus mollitia vel
              expedita id nulla labore, dolorem ane.
            </i>
            <br/>
            <a className='btn btn-contact fw-bold' href="/contact-us">Get Directions</a>
          </div>
        


        </div> */}

        {/* <div className="row">
          <div className="col-sm-6">
            <div className="fw-bold">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima blanditiis iste excepturi placeat possimus dolor quos facere k</div>
            <p className="">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima blanditiis iste excepturi placeat possimus dolor quos facere asperiores recusandae culpa eius voluptatibus mollitia vel expedita id nulla labore, dolorem ane.
            </p>

            <ul className="contactul list-unstyled" style={{paddingLeft:'0px'}}>
              <li className="fw-bold my-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima blanditiis 
              <FontAwesomeIcon icon={faCircle}  className="dots px-2"/>
              </li>

              <li className="fw-bold my-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima blanditiis 
              <FontAwesomeIcon icon={faCircle}  className="dots px-2"/>
              </li>

              <li className="fw-bold my-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima blanditiis 
              <FontAwesomeIcon icon={faCircle}  className="dots px-2"/>
              </li>

              <li className="fw-bold my-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima blanditiis 
              <FontAwesomeIcon icon={faCircle}  className="dots px-2"/>
              </li>

              <li className="fw-bold my-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima blanditiis 
              <FontAwesomeIcon icon={faCircle}  className="dots px-2"/>
              </li>
           
            </ul>
          </div>

          <div className="col-sm-6">

          <Form onSubmit={handleSubmit2}>
            <Form.Group controlId="name2" className="my-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData2.name}
                onChange={handleChange2}
                required
              />
            </Form.Group>

            <Form.Group controlId="email2" className="my-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData2.email}
                onChange={handleChange2}
                required
              />
            </Form.Group>

            <Form.Group controlId="message2" className="my-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData2.message}
                onChange={handleChange2}
                rows={4}
                required
              />
            </Form.Group>
          <div className="text-end">
            <button type="submit" className="btn btn-danger my-3">
                Submit
            </button>
          </div>
          </Form>

          </div>
        </div> */}

    </div>
      <Footer />
    </>
  );
}
