// @flow
import * as React from 'react';
import workInaus from '../static/images/study-in-canada-study-in-abroad.jpg';
import Image from "react-bootstrap/Image";
import { MyNav } from '../layouts/MyNav';
import { Footer } from '../layouts/Footer';
export function StudyInCanada() {
  return (
    <>
    <MyNav />
     <div className='container my-4'>
        <div className='row'>
            <div className='col-sm-12'>
            <Image
                  src={workInaus}
                  rounded
                  className="img-fluid my-2 w-100"
                  style={{ }}
                />
            </div>

            <div className='col-sm-12'>
                <div className='display-6 fw-600 red my-3'>
                        Why study in Canada?
                </div>
                <p>
                Canada is one of the best places in the world for higher education. One gets world class education which is recognized globally. University and colleges of Canada support students and give them best learning experience. Also available - scholarships, work and internship opportunities. Other reasons to prefer Canada include -
                </p>

              
                <ul>
                    <li>Affordable education</li>
                    <li>Research opportunities</li>
                    <li>Exciting campus environment</li>
                    <li>Diverse learning outcomes</li>
                    <li>Health and safety</li>
                    <li>Internships and work opportunities</li>
                    <li>Lucrative Educational and career prospects</li>
                    <li>Possibility of settlement post course</li>
                    <li>Vibrant campus environment</li>
                </ul>

                <h2 className='h3 fw-600 text-green'>
                Minimum eligibility criteria for studying in Canada
                </h2>
                <ul>
                    <li>Graduates with 50 percent marks are eligible</li>
                    <li>Degree must be completed within stipulated time</li>
                    <li>No year backs</li>
                    <li>Likewise, no more than 10 backlogs in graduation period</li>
                </ul>
                <h3 className='h3 fw-600 text-green'>
                Minimum age limit
                </h3>
                <p>
                Person should be less than 45 years of age
                </p>
                <h3 className='h3 fw-600 text-green'>
                Stay back period -

                </h3>
                <ul>
                    <li>Students need to have minimum 8 months course for the above subject</li>
                    <li>Stay for lesser duration is not allowed</li>
                    <li>Courses of less than 2 years carry on work permit for same duration</li>
                    <li>For more than two years course, the minimum stay time is 3 years.</li>
                </ul>

                <h3 className='h3 fw-600 text-green'>
                Student visa requirements -

                </h3>
                <p>
                The following documents are mandatory for your student visa application process to Canada -
                </p>

                <ul>
                    <li>Valid passport</li>
                    <li>Proof of payment</li>
                    <li>Proof of financial situation or funds</li>
                    <li>Letter of acceptance from the educational institution</li>
                    <li>Passport size photograph</li>
                    <li>English proficiency score</li>
                    <li>Study permit</li>
                    <li>Visa</li>
                </ul>
                <div className='fw-bold'>For any other, the documents can be asked by the colleges.</div>
         
         
                <h3 className='h3 fw-600 text-green'>
                IELTS score or CLB requirements -

                </h3>
                <p>
                For seeking Canadian student visa, a candidate needs to have the Canadian Language Benchmark (CLB) or -
                </p>

                <ul>
                   <li>IELTS score of 5.5 bands in each module for undergraduate program</li>
                   <li>Overall 6 or more for undergraduate program</li>
                   <li>For graduation program - Minimum IELTS score is 6 each</li>
                   <li>Overall graduation program minimum score is 6.5</li>
                </ul>
         
            </div>
        </div>
     </div>

     <Footer />
    </>
  );
};