// @flow
import * as React from "react";
import stipendojtprogram from "../static/images/stipendojtprogram.jpg";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";

export function Stipendojtprogram() {
  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Image
              src={stipendojtprogram}
              rounded
              className="img-fluid my-2"
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-sm-12">
            <h1 className="h2 fw-600 text-green mt-3">
              Stipend OJT Program
            </h1>
            <p>
              The Stipend OJT Program offers candidates an opportunity to gain relevant work experience while earning a stipend amount of AUD$600 to AUD$1,000 (depending on qualifications and previous work experience) for monthly travel and meal allowance. The Stipend OJT’s are available in the following fields: Marketing, Human Resources, and Events Management.
            </p>

            <h2 className="h2 fw-600 text-green">PROGRAM DETAILS:</h2>
            <ul>
              <li>Tailored training related to your skills and academic background</li>
              <li>20 - 24 weeks, full-time training positions</li>
              <li>PAID - Stipend Amount: AUD $600 - $1,000 monthly travel and meal allowance</li>
              <li>Access to over 7,000 companies Australia wide</li>
              <li>Start every Monday (pending lead time)</li>
              <li>407 Training Visa Sponsorship – pending eligibility</li>
              <li>Flexibility with location is required</li>
              <li>Academic credit available if required</li>
              <li>Fields: Marketing, Human Resources, and Events Management</li>
            </ul>

            <h2 className="h2 fw-600 text-green">ELIGIBILITY</h2>
            <ul>
              <li>Aged 18-40</li>
              <li>The OJT field requested is directly related to your educational background</li>
              <li>Currently enrolled in a tertiary course (bachelor degree or above), or</li>
              <li>A recent graduate within 12 months (at the time of visa submission) of course completion (bachelor degree or above)</li>
              <li>Upper intermediate-advanced English language skills (equivalent to IELTS 6.0)</li>
              <li>Minimum 4 months related work experience in your field of Marketing, Events, or HR</li>
              <li>If you hold an Australian Visa with full work rights or are applying for an Australian Visa with full work rights, we offer flexibility in regards to eligibility criteria</li>
            </ul>

            <h2 className="h2 fw-600 text-green">START DATES AND LEAD TIME</h2>
            <p>
              Candidates have the flexibility of choosing their preferred start dates (allow for 3-4 month lead time):
            </p>
            <ul>
              <li>4 months lead time for 407 Training Visa candidates</li>
              <li>3 months lead time for candidates with proof of suitable working visas (e.g. Working Holiday Visa)</li>
              <li>This can be flexible if you are applying for an existing vacancy.</li>
            </ul>

            <h2 className="h2 fw-600 text-green">VISA SPONSORSHIP</h2>
            <p>
              The Department of Home Affairs has approved Australian OJT as a Sponsor for the Subclass 407 Training visa. Will assess your eligibility and provide advice on the required documentation to support your visa application.
            </p>

            <h2 className="h2 fw-600 text-green">APPLICATION PROCESS</h2>
            <ol className="pb-4">
              <li>
                <strong>Enquiry and Assessment:</strong> Complete the online form and submit initial documentation to find out whether you are eligible for the program.
              </li>
              <li>
                <strong>Application & Processing:</strong> Eligible candidates will be requested to complete and return the application form along with the supporting documents and application fee. You will then provide us an application video after which Australian Host Organisation will negotiate your OJT details.
              </li>
              <li>
                <strong>Confirmation & Payment:</strong> Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> will arrange an interview for you with the Host Organisation after which the OJT will be confirmed through a formal Training Agreement.
              </li>
              <li>
                <strong>Visa & Travel Arrangements:</strong> Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> to negotiate with The Department of Home Affairs regarding visa processing for you. Or, you can make alternative arrangements (e.g. Working Holiday Visa). You are to confirm with Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> your arrival details.
              </li>
              <li>
                <strong>Program Participation:</strong> Congratulations! You are about to embark on an incredible journey. You will have an orientation when you arrive in the Pacific Islands. Once you begin your OJT, we will be asking for your continued feedback and continue to provide you with support.
              </li>
            </ol>
{/* 
            <p>
              *Additional programs are available. Please contact us for further details related to Stipend OJT Vacancies!
            </p> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
