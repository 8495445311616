// @flow
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // const  = {
  //   top: '191px',
  //   fontFamily: 'Inter',
  //   fontStyle: 'normal',
  //   fontWeight: 700,
  //   fontSize: '64px',
  //   lineHeight: '135.2%',
  //   color: '#000000',
  // };

  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row my-5">
          <div className="col-12 mb-3">
            <p>
              <i className='display-5 fw-600'>Anhad Immigration</i>
              <i> - Mentors to the future global scholars</i>
            </p>
            <hr />
          </div>

          <div className="col-md-12">
            <p style={{ lineHeight: '35px' }}>
              Anhad Immigration group is an educational advisory and consultancy service provider to students
              who wish to study in UK, Canada, and the USA. At our organization, we aim to offer the
              students with concrete results for realizing their dreams to study in international colleges. We
              also offer additional guidance and mentorship program at no additional cost in three phases-
            </p>
            <ul style={{ width: '100%'}}>
              <li>Pre-application phase</li>
              <li>Application phase</li>
              <li>Post application and immigration guidance</li>
            </ul>

            <hr className="my-5" />

            <p>
            
              <i className='display-5 fw-600'>Realizing the right results in a timely manner</i>

            </p>

            <p style={{ lineHeight: '35px' }}>
              With us, the first thing you observe is that we don't beat around the bush. We will have a
              detailed review of the educational documents presented by you and subsequently will offer
              you the exact mentorship needed for pursuing your case.
              Taking admission in the foreign universities is not an easy task yet with us, all this is going to
              be highly simple for you. We do all the groundwork for you to give you the least possible hassle
              in the process. We have experts in education and legal compliance that ensures that all your
              needs are met in a single place.
            </p>

            <hr className="my-5" />

          </div>

          <div className="col-md-12 col-12" style={{ lineHeight: '35px' }}>
            <p>
              
              <i className='display-5 fw-600'>
              Detailed assistance services at no additional cost! 
              </i>

            </p>
            The Rise en shine group is committed to providing the students with the right educational
            mentorship and counseling session to get the best results within the desired budget. We are
            already impacting the lives of numerous aspirants in boosting their application process
            through the presence of dedicated service experts in these countries. We offer assistance in
            the following matters as well -
            <ul style={{ width: '100%' }}>
              <li>
                <b>Choosing the right educational institution</b> - We help you in selecting the right
                university for your needs. Our experts help in shortlisting the right course and
                syllabus outline as per your previous study and interest.
              </li>
              <li>
                <b>Statement of purpose and Letter of intent</b> - With us, you gain access to the best
                statement of purpose design to help with gaining weight in the application process
                and immigration to the best of nations. These are further needed for using the
                attempt at the best college application and scholarship programs. We help you with
                the SOP, LOR, university-related essays, and similar assessment methods.
              </li>
              <li>
                <b>Application process</b> - We help you with the filing paperwork for the right college and
                ensure that you meet the necessary eligibility criteria for the successful entry to the
                same. We also handle your queries at every process and act as an important
                facilitator between you and your college.
              </li>
              <li>
                <b>Visa counsel</b> - With a view to strengthen your chances of getting easy entry to the
                college of your choice, we have Visa interview training process so that you can easily
                approach the embassy and are able to handle their queries in the best possible way.
              </li>
              <li>
                <b>University interview</b> - As your visa experts and education mentors, we prepare you
                for the interview with the university officials and help in building your confidence. As a
                result of this, you can easily join any university of your choice and get the desired
                results.
              </li>
            </ul>
            We welcome you to rediscover the educational visa process with the right advice and
            mentorship opportunities from our experts.
          </div>
          <div className="col-1 d-md-block d-none"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};
