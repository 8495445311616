
import * as React from "react";
import earlychildhood from "../static/images/early-childhood.jpg";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
export function Earlychildhoodprogram() {
  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Image
            src={earlychildhood}
              rounded
              className="img-fluid my-2"
              style={{width: "100%",maxHeight:"585px",objectFit:"cover"}}
            />
          </div>

          <div className="col-sm-12">
            <h1 className="h2 fw-600 text-green mt-3">
              Early Childhood Program - OJT
            </h1>
            <p>
              The Early Childhood Education Program is a paid program offered to highly skilled candidates that are completing or have completed Early Childhood Education and Care (ECEC) studies and have relevant childcare experience.
            </p>
            <p>Positions are available: Early Childhood Educator and Early Childhood Teacher (ECT).</p>

            <h2 className="h2 fw-600 text-green">PROGRAM DETAILS:</h2>
            <ul>
              <li>Tailored training related to your skills and academic background</li>
              <li>12-month positions, full time</li>
              <li>PAID - Australian Award Wage: minimum $24 per hour</li>
              <li>Start every Monday (pending lead time)</li>
              <li>407 Training Visa Sponsorship</li>
              <li>Flexibility with location is required</li>
              <li>Academic credit may be obtained if required</li>
            </ul>

            <h2 className="h2 fw-600 text-green">WHY COMPLETE AN AUSTRALIAN OJT?</h2>
            <ul>
              <li>Internationalize your resume</li>
              <li>Gain valuable experience in your academic field</li>
              <li>Obtain credit for your existing studies</li>
              <li>Develop workplace skills</li>
              <li>Form alliances with international companies</li>
              <li>Participate in a cultural exchange program</li>
              <li>Gain recognition within your profession</li>
              <li>Improve your career options</li>
              <li>Develop an appreciation of the Australian work ethic and labour procedures</li>
            </ul>

            <h2 className="h2 fw-600 text-green">ELIGIBILITY</h2>
            <ul>
              <li>Age: 18 – 35 years. Up to 50 years in certain regional locations.</li>
              <li>Nationality: All</li>
              <li>Field: Studies and experience must be related to early childhood</li>
              <li>Preference: Currently working or studying in early childhood</li>
              <li>Flexible: Willing to work in any Australian city, including rural and remote locations</li>
              <li>English: Upper intermediate-advanced English language skills</li>
              <li>Work Experience: 
                <ul>
                  <li>Minimum 6 months full-time in early childhood is desirable but not a requirement for recent graduates</li>
                  <li>Minimum of 2 years’ experience within the early childhood education industry in the last 5 years</li>
                </ul>
              </li>
            </ul>

            <h2 className="h2 fw-600 text-green">VISA SPONSORSHIP</h2>
            <p>
              The Department of Home Affairs has approved Australian OJT as a Sponsor for the Subclass 407 Training visa. will assess your eligibility and provide advice on the required documentation to support your visa application.
            </p>

            <h2 className="h2 fw-600 text-green">APPLICATION PROCESS</h2>
            <ol>
              <li>
                <strong>Enquiry and Assessment:</strong> Complete the online form and submit initial documentation to find out whether you are eligible for the program.
              </li>
              <li>
                <strong>Application & Processing:</strong> Eligible candidates will be requested to complete and return the application form along with the supporting documents and application fee. You will then provide us an application video after which Australian Host Organisation will negotiate your OJT details.
              </li>
              <li>
                <strong>Confirmation & Payment:</strong> Australian  <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority</a> will arrange an interview for you with the Host Organisation after which the OJT will be confirmed through a formal Training Agreement.
              </li>
              <li>
                <strong>Visa & Travel Arrangements:</strong> Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a>  to negotiate with The Department of Home Affairs regarding visa processing for you. Or, you can make alternative arrangements (e.g. Working Holiday Visa). 
                You are to confirm with Australian  <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank">Office of the Migration Agents Registration Authority </a> your arrival details.
              </li>
              <li>
                <strong>Program Participation:</strong> Congratulations! You are about to embark on an incredible journey. You will have an orientation when you arrive in the Pacific Islands. Once you begin your OJT we will be asking for your continued feedback and continue to provide you with support.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
