// @flow
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
import Stuloan from "../static/images/studentloann.jpg";
import Image from "react-bootstrap/Image";
import React, { useState } from 'react';

export function StudentLoan() {
  const [successconnectMessage, setConnectMessage] = useState('');
  const [formData, setFormData] = useState({
    First_Name: '',
    Last_Name: '',
    Email_Address: '',
    Data_Science: '',
    Dob: '',
    Tenth_marks: '',
    Twelveth_marks: '',
    What_are_you_looking_for: '',
    Grade_marks: '',
    Years_of_Exp: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: null }); // Clear error when user starts typing
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted!");
    console.log(formData);

    try {
      const response = await fetch("https://anhad.pythonanywhere.com/api/loan-forms/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // const data = await response.json();
        // console.log("Success:", data);
        setConnectMessage("Form Submit successfully!");

        // Handle success, update UI, etc.

        // You can reset the form fields here if needed
        setFormData({
          First_Name: '',
          Last_Name: '',
          Email_Address: '',
          Data_Science: '',
          Dob: '',
          Tenth_marks: '',
          Twelveth_marks: '',
          What_are_you_looking_for: '',
          Grade_marks: '',
          Years_of_Exp: '',
        });
      } else {
        const errorData = await response.json();
        console.error("Error data:", errorData);

        // Update state to show errors
        setErrors(errorData);
      }
    } catch (error) {
      console.error("Network error:", error);
      // Handle network error, update UI, show error message, etc.
    }
  };

  


  return (
    <>
      <MyNav />
      <div
        className=""
        style={{
          position: "relative",
          isolation: "isolate",
        }}
      >
        <Image src={Stuloan} className="img-fluid position-relative" />
        <div className="studentEle d-none d-xl-block">
          <div className="display-5 fw-bold red">
            Explore Your <br />
            Education World
          </div>
          <div className="my-5">
            <i className="h1" style={{ color: "#9C6666" }}>
              Don't let any barries stop you
            </i>
          </div>
        </div>
      </div>

      <div className="container py-4 my-5">
        <div className="row py-4">
          <div className="col-sm-12">
            <div className="cardLinear h2 text-white p-4 rounded-5">
              What are we doing?
            </div>
            <p>
              <i>
              Providing Funding / Loan solutions for students aiming to study abroad.
              </i>
            </p>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-sm-12">
            <div className="cardLinear h2 text-white p-4 rounded-5">
            Why are we doing it?
            </div>
            <p>
              <i>
              To help students achieve their dreams and make their Loan journey seamless through one source of contact.          	
              </i>
            </p>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-sm-12">
            <div className="cardLinear h2 text-white p-4 rounded-5">
            How are we going to do it?
            </div>
            <p>
              <i>
                We will get leads from our Recruitment Partners and Direct Students to provide one point funding solution with funding channel partners to expedite this process with quick and high conversion rates.
              </i>
            </p>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-sm-12">
            <div className="cardLinear h2 text-white p-4 rounded-5">
            Benefits For Our Students
            </div>
            
            <ul>
              <li><i>Gaining more trust with students.</i></li>
              <li><i>Providing funding solutions to students from one source itself.</i></li>
              <li><i>Income Tax benefits under section 80E for the payer.</i></li>
              <li><i>Preserving their family savings.</i></li>
              <li><i>Building a good credit history.</i></li>
              <li><i>Opportunity for students to take their own financial responsibilities.</i></li>
              <li><i>We have partnered with top financial institutions, resulting in best-in-class acceptance rates and sonic processing time.</i></li>
            </ul>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-sm-12">
            <div className="cardLinear h2 text-white p-4 rounded-5">
            Interest Rates
            </div>
            <p>
              <i>
              Ranging between 9.5% to 13.99% depending upon the student's profile, program, eligibility and loan amount required.
              </i>
            </p>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-sm-12">
            <div className="cardLinear h2 text-white p-4 rounded-5">
            Turn Around Time
            </div>
            <p>
              <i>
                Ranging between 1 week to 3 weeks until disbursement is done once provided with all required documents.              </i>
            </p>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-sm-8 myloan-form">
          <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.First_Name ? 'is-invalid' : ''}`}
            placeholder="First Name"
            name="First_Name"
            value={formData.First_Name}
            onChange={handleChange}
          />
          {errors.First_Name && (
            <div className="invalid-feedback">{errors.First_Name[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.Last_Name ? 'is-invalid' : ''}`}
            placeholder="Last Name"
            name="Last_Name"
            value={formData.Last_Name}
            onChange={handleChange}
          />
          {errors.Last_Name && (
            <div className="invalid-feedback">{errors.Last_Name[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="email"
            className={`form-control ${errors.Email_Address ? 'is-invalid' : ''}`}
            placeholder="Email Address"
            name="Email_Address"
            value={formData.Email_Address}
            onChange={handleChange}
          />
          {errors.Email_Address && (
            <div className="invalid-feedback">{errors.Email_Address[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.Data_Science ? 'is-invalid' : ''}`}
            placeholder="Data Science"
            name="Data_Science"
            value={formData.Data_Science}
            onChange={handleChange}
          />
          {errors.Data_Science && (
            <div className="invalid-feedback">{errors.Data_Science[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="date"
            className={`form-control ${errors.Dob ? 'is-invalid' : ''}`}
            placeholder="Date of Birth"
            name="Dob"
            value={formData.Dob}
            onChange={handleChange}
          />
          {errors.Dob && (
            <div className="invalid-feedback">{errors.Dob[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.Tenth_marks ? 'is-invalid' : ''}`}
            placeholder="10th Marks"
            name="Tenth_marks"
            value={formData.Tenth_marks}
            onChange={handleChange}
          />
          {errors.Tenth_marks && (
            <div className="invalid-feedback">{errors.Tenth_marks[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.Twelveth_marks ? 'is-invalid' : ''}`}
            placeholder="12th Marks"
            name="Twelveth_marks"
            value={formData.Twelveth_marks}
            onChange={handleChange}
          />
          {errors.Twelveth_marks && (
            <div className="invalid-feedback">{errors.Twelveth_marks[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.What_are_you_looking_for ? 'is-invalid' : ''}`}
            placeholder="What are you looking for?"
            name="What_are_you_looking_for"
            value={formData.What_are_you_looking_for}
            onChange={handleChange}
          />
          {errors.What_are_you_looking_for && (
            <div className="invalid-feedback">{errors.What_are_you_looking_for[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.Grade_marks ? 'is-invalid' : ''}`}
            placeholder="Grade Marks"
            name="Grade_marks"
            value={formData.Grade_marks}
            onChange={handleChange}
          />
          {errors.Grade_marks && (
            <div className="invalid-feedback">{errors.Grade_marks[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <input
            type="text"
            className={`form-control ${errors.Years_of_Exp ? 'is-invalid' : ''}`}
            placeholder="Years of Experience"
            name="Years_of_Exp"
            value={formData.Years_of_Exp}
            onChange={handleChange}
          />
          {errors.Years_of_Exp && (
            <div className="invalid-feedback">{errors.Years_of_Exp[0]}</div>
          )}
        </div>
        <div className="my-2 form-group col-lg-6">
          <button type="submit" className="btn btn-red">
            Submit
          </button>
        </div>
      </div>
    </form>

    {successconnectMessage && (
                  <div className="alert alert-success my-2" role="alert">
                    {successconnectMessage}
                  </div>
                )}

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
