import React, { useState, useEffect } from "react";
// import { PROGRAM_CATEGORIES,PROGRAM_SUB_CATEGORIES } from "./Constants";
import { Modal, Button } from "react-bootstrap";
import { Form } from 'react-bootstrap';

const Country = () => {
  const countries = ["", "Canada", "USA", "UnitedKingdom", "Austrailia"];
  const provinces = {
    Canada: [
      "Ontario",
      "Quebec",
      "British Columbia",
      "New Brunswick",
      "Manitoba",
      "Nova Scotia",
      "Alberta",
      "Saskatchewan",
      "Yukon",
      "Prince Edward Island",
      "Newfoundland and Labrador",
    ],
    USA: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Dist. Columbia",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    UnitedKingdom: [   
      "Scotland",
      "Northern Ireland",
      "Wales",
      "North East",
      "North West",
      "Yorkshire and the Humber",
      "West Midlands",
      "East Midlands",
      "South West",
      "South East",
      "East of England",
      "Greater London",
    ],
    Austrailia: [   
      "Australian Capital Territory",
      "New South Wales",
      "Northern Territory",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Victoria",
      "Western Australia",
    ],

  };

  const cities = {
    Alberta: [
      "",
      "Edmonton",
      "Red Deer",
      "Rocky Mountain House",
      "Olds",
      "Calgary",
      "Medicine Hat",
      "Cochrane",
      "Chestermere",
      "Airdrie",
      "Fort McMurray",
      "Vermillion",
      "Lethbridge",
      "Brooks",
      "Drumheller",
      "Three Hills",
      "Strathmore",
      "Banff",
      "Canmore",
    ],

    BritishColumbia: [
      "",
      "Richmond",
      "New Westminster",
      "Coquitlam",
      "Prince Rupert",
      "Terrace",
      "Smithers",
      "Burnaby",
      "Abbotsford",
      "Vancouver",
      "Nelson",
      "Delta",
      "Squamish",
      "Pemberton",
      "Brackendale",
      "Whistler",
      "Shawnigan Lake",
      "Summerland",
      "Langley City",
      "Surrey",
      "Victoria",
      "Castlegar",
      "Nanaimo",
      "Kelowna",
      "Prince George",
      "Port Moody",
      "Port Coquitlam",
      "Penticton",
      "Vernon",
      "Salmon Arm",
      "Chilliwack",
      "Cranbrook",
      "Dawson Creek",
      "Fort St. John",
      "Fort St John",
      "Hudson's Hope",
      "Maple Ridge",
      "Pitt Meadows",
      "North Vancouver",
      "Kamloops",
      "Langley",
      "Mill Bay",
      "Ladysmith",
      "Chemainus",
      "Duncan",
      "Lake Cowichan",
    ],
    Manitoba: ["", "Winnipeg", "Brandon"],
    NewBrunswick: [
      "",
      "Moncton",
      "St. Stephen",
      "Sackville",
      "Fredericton",
      "Miramichi",
      "St. Andrews",
      "Saint John",
      "Woodstock",
    ],
    NewfoundlandandLabrador: [
      "",
      "Baie Verte",
      "Stephenville",
      "Bonavista",
      "Burin Bay Arm",
      "Carbonear",
      "Clarenville",
      "Corner Brook",
      "Gander",
      "Grand Falls-Windsor",
      "Happy Valley-Goose Bay",
      "Labrador City",
      "Placentia",
      "Port aux Basques",
      "St. John's",
      "Conception Bay South",
      "St. Anthony",
    ],
    NovaScotia: ["", "Windsor", "Sydney", "Wolfville", "Halifax"],
    Ontario: [
      "",
      "Kitchener",
      "Thunder Bay",
      "Stoney Creek",
      "Hamilton",
      "Toronto",
      "Mississauga",
      "Niagara-on-the-Lake",
      "Burlington",
      "Waterloo",
      "Brantford",
      "Windsor",
      "Pickering",
      "Ottawa",
      "Thornhill",
      "London",
      "Vaughan",
      "LaSalle",
      "Belle River",
      "Essex",
      "Kingsville",
      "Leamington",
      "Tecumseh",
      "Revelstoke",
      "Cambridge",
      "Oakville",
      "Acton",
      "Milton",
      "Georgetown",
      "North York",
      "Aurora",
      "Barrie",
      "St. Catharines",
      "Markham",
      "Guelph",
      "Nepean",
      "Brampton",
      "Kanata",
      "Gloucester",
      "Kingston",
      "Sault Ste. Marie",
      "Welland",
      "Peterborough",
      "Oshawa",
      "North Bay",
      "Kirkland Lake",
      "South Porcupine",
      "Haileybury",
      "Perth",
      "Sudbury",
      "Midland",
      "Orangeville",
      "Orillia",
      "Owen Sound",
      "Collingwood",
      "Vancouver",
      "Scarborough",
      "Richmond Hill",
      "Unionville",
      "Newmarket",
      "Woodbridge",
      "Caledon East",
      "Keswick",
      "King City",
      "Bolton",
      "York",
      "Willowdale",
      "Lasalle",
      "Maple",
      "Etobicoke",
      "Belleville",
      "Stouffville",
      "Sutton",
      "Chatham",
      "Orleans",
      "Richmond",
      "West Carleton",
      "Arva",
      "Lindsay",
      " Scarborough",
      " Toronto",
      "Whitby",
      "Cobourg",
      "Scarborough ",
      "Brockville",
      "Hanmer",
      "Greater Sudbury",
      "Simcoe",
      "St. Thomas",
      "Woodstock",
      "Shelburne",
      "Fergus",
      "Erin",
      "Mount Forest",
      "Palmerston",
      "Dundas",
      "Ancaster",
      "1715 Main Street East",
      "Waterdown",
      "Niagara Falls",
      "Orléans",
    ],
    PrinceEdwardIsland: [
      "",
      "Charlottetown",
      "Summerside",
      "Alberton",
      "Georgetown",
    ],
    Quebec: [
      "",
      "Montreal",
      "Saint-Lambert",
      "La Pocatière",
      "Greenfield Park",
      "Saint-Hubert",
      "St. Hubert",
      "Laval",
      "Longueuil",
      "Pointe-Claire",
      "Sherbrooke",
      "St. Léonard",
      "Rigaud",
      "Montréal",
      "Quebec City",
      "Montreal ",
    ],
    Saskatchewan: [
      "",
      "Saskatoon",
      "Yorkton",
      "Moose Jaw",
      "Prince Albert",
      "Regina",
    ],
    Yukon: ["", "Whitehorse"],

  };

  return {
    countries,
    provinces,
    cities,
  };
};



// const Loader = () => {
//   const [display, setDisplay] = useState("block");

//   const show = () => setDisplay("block");
//   const hide = () => setDisplay("none");

//   return (
//     <div id="loader" style={{ textAlign: "center", display }}>
//       <span className="loader" style={{ display: "inline-block" }}></span>
//     </div>
//   );
// };



export default function CourseDetail() {
  const [schools, setSchools] = useState([]);
  // const [enquiries, setEnquiries] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [sn, setSn] = useState("");
  const [cn, setCn] = useState("");
  // const [helpMessage, setHelpMessage] = useState("");
  // const [loadMoreButton, setLoadMoreButton] = useState(0);
  // const [lastLoadedTill, setLastLoadedTill] = useState(0);
  const [countries, setCountries] = useState(Country());
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [data, setData] = useState([]); 
  const [programdata, setProgramsdata] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  // const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  

const handleClose = () => setShowModal(false);
const handleShow = () => setShowModal(true);
const handleCloseModal = () => setSecondModal(false);
const handleOpenModal = (parentSchool, parentProgram) => {
  // console.log("Selected School Name:", parentSchool.name); // Use the school parameter
  // console.log("Selected Program Name:", parentProgram.name);

  setSelectedSchool(parentSchool.name);
  setSelectedProgram( parentProgram.name);
  // Show the second modal
  setSecondModal(true);
  setFormData({
    ...formData,
    parentSchool: parentSchool.id,
    // school_id: parentSchool.id,
    parentProgram: parentProgram.id,
    // program_id: parentProgram.id,
  });
};

  const [formData, setFormData] = useState({
    parentSchool: '',
    parentProgram: '',
    customer_name: '',
    customer_phone: '',
    customer_email: '',
   
    customer_query: '',
    remarks: '',
  });

// console.log("outside::",selectedSchool);
  const handleNameSelect = (selectedValue) => {
    setSelectedSchool(selectedValue);
    setFormData({
      ...formData,
      parentSchool: selectedValue,
    });
  };
  
  const handleProgramNameSelect = (selectedValue) => {
    setSelectedProgram(selectedValue);
    setFormData({
      ...formData,
      parentProgram: selectedValue,
    });
  };

    const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  
// const handleOpenModal = () => setIsModalOpen(true);
  useEffect(() => {
    // Fetch schools
    fetch('https://anhad.pythonanywhere.com/courses/api/myschool/')
      .then(response => response.json())
      .then(data => {
        setSchools(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching schools:', error);
        setIsLoading(false);
      });

    
      fetch('https://anhad.pythonanywhere.com/courses/api/mytopscholl/')
      .then(response => response.json())
      .then(data => {
        // console.log('Fetched data:', data);
    
        // Assuming that data.PROGRAM_CATEGORIES and data.PROGRAM_SUB_CATEGORIES are arrays
        setCategories(data.PROGRAM_CATEGORIES);
        setSubCategories(data.PROGRAM_SUB_CATEGORIES);
      })
      .catch(error => {
        console.error('Error fetching categories and subcategories:', error);
      });
    

    fetch('https://anhad.pythonanywhere.com/courses/api/myprograms/')
      .then(response => response.json())
      .then(data => {
        setPrograms(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching programs:', error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getTopSchools();
  }, []);

  const getProvinces = (selectedCountry) => countries.provinces[selectedCountry] || [];
  const getCities = (selectedProvince) => countries.cities[selectedProvince] || [];
  const getSubCategories = (selectedCategory) => {
    if (selectedCategory === "") return [];
    return subCategories.filter(
      (item) => item.parent.toString() === selectedCategory.toString()
    );
  };


  const toggleShowAll = (school) => {
    school.utility.show_all = 1 - school.utility.show_all;
  };


  const search = () => {
    setIsLoading(true);
 
    fetch('https://anhad.pythonanywhere.com/courses/api/searchquery/', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    qt: 's',
    qp: {
      sn,
      cn,
      cat: selectedCategory,
      sub_cat: selectedSubCategory,
      c: country,
      p: province,
      ci: city,
      slt: 25,
    },
  }),
})
  .then(response => response.json())
  .then(data => {
    // Check the structure of the response
    // console.log(data);

    if (data.status === "success") {
      if (Array.isArray(data.s)) {
        // Assuming that `data.s` is the array of schools
        setData(data.s);
        handleShow(); // Show the modal when data is available

      } else {
        console.error('Invalid schools data format:', data.s);
      }

      if (Array.isArray(data.c)) {
        // Assuming that `data.c` is the array of grades or categories
        // console.log('Programs data:', data.c);
        setProgramsdata(data.c)
        // setCategories(data.c);
      } else {
        console.error('Invalid categories data format:', data.c);
      }

      setIsLoading(false);
    } else {
      console.error('Invalid response format:', data);
      setIsLoading(false);
    }
  })
  .catch(error => {
    console.error('Error:', error);
    setIsLoading(false);
  });


  };





  const submitNewEnquiryForm = async (e) => {
  e.preventDefault();
  // console.log("Form submitted!");
  // console.log(formData);

  try {
     
    const response = await fetch(
      "https://anhad.pythonanywhere.com/courses/api/myenquiry/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_email: formData.customer_email,
          customer_name: formData.customer_name,
          customer_phone: formData.customer_phone,
          customer_query: formData.customer_query,
          parentProgram: formData.parentProgram,
          parentSchool: formData.parentSchool,
          remarks: formData.remarks,
        }),
      }
    );

    if (response.ok) {
      setSuccessMessage("Form submitted successfully!"); // Set success message

      // You can reset the form fields here if needed
      setFormData({
        customer_email:"",
        customer_name: "",
        customer_phone:"",
        customer_query:"",
        parentProgram: "",
        parentSchool:  "",
        remarks:       "",
      });
        // Close the modal
        setIsModalOpen(false);

        // Reset the success message for the next time
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000); // Adjust the timeout as needed
    } else {
      console.error("Error sending form data:", response.statusText);
      const errorData = await response.json(); // Log the error response data
      console.error("Error data:", errorData);
    }
  } catch (error) {
    console.error("Network error:", error);
  }
};

  
  const getTopSchools = () => {
    // Implement logic to get top schools
  };

  
  const isDataLoaded = categories.length > 0 && subCategories && subCategories.length > 0;

  return (
    <>

  <div className="hero-slider-form">

   
      <div className="py-2">
       
        <input
          type="text"
          className="form-control"
          placeholder="Enter Course Name"
          autoComplete="off"
          value={cn}
          onChange={(e) => setCn(e.target.value)}
        />
      </div>

      {isDataLoaded && (
  <>
   <div className="py-2">
 
  <select
    className="form-control"
    value={selectedCategory}
    onChange={(e) => setSelectedCategory(e.target.value)}
    required  
  >
    <option value="" disabled>Select a category</option>
    {categories.map((category) => (
      <option key={category.id} value={category.id}>
        {category.name}
      </option>
    ))}
  </select>
</div>


    <div className="py-2">
      <select
        className="form-control"
        value={selectedSubCategory}
        onChange={(e) => setSelectedSubCategory(e.target.value)}
        required  
      >
        <option value="" disabled>Select a subcategory</option>
        {getSubCategories(selectedCategory).map((subCategory) => (
          <option key={subCategory.id} value={subCategory.id}>
            {subCategory.name}
          </option>
        ))}
      </select>
    </div>

  </>
)}


      <div className="py-2">
        <select
          className="form-control"
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
            setProvince(""); // Reset province when country changes
            setCity(""); // Reset city when country changes
          }}
        >
          <option value="" disabled>Select a country</option>
          {countries.countries.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </select>
      </div>


      {/* <div className="" style={{ display: country.length !== 0 ? 'block' : 'none' }}>
        Province:
        <select
          className="form-control"
          value={province}
          onChange={(e) => setProvince(e.target.value)}
        >
          <option value="">Select Province</option>
          {getProvinces(country).map((provinceOption) => (
            <option key={provinceOption} value={provinceOption}>
              {provinceOption}
            </option>
          ))}
        </select>
      </div> */}

      {/* <div className="" style={{ display: province.length !== 0 && country.length !== 0 ? 'block' : 'none' }}>
        City:
        <select
          className="form-control"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        >
          <option value="">Select City</option>
          {getCities(province).map((cityOption) => (
            <option key={cityOption} value={cityOption}>
              {cityOption}
            </option>
          ))}
        </select>
      </div> */}


      <div className=" mt-2">
              <button className="btn large btn-light bg-white" disabled={isLoading} onClick={search}>
                Search
              </button>
      </div>

</div>




<Modal show={showModal} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Search Results</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  {data && data.length > 0 ? (
    <div>
      {data
        .filter((school) =>
          programdata.some((program) => program.parentSchool_id === school.id)
        )
        .map((school, index) => {
          // Define program in the outer scope
          const programsForSchool = programdata.filter(
            (program) => program.parentSchool_id === school.id
          );

          return (
            <div key={index}>
              <div className="d-flex justify-content-between flex-wrap">
                <h4 className="text-blue">{school.name}</h4>
                <div className="d-flex gap-2">
                  <h6>{school.country},</h6>
                  <h6>{school.city}</h6>
                </div>
              </div>

              {programsForSchool.map((program, programIndex) => (
                <div key={programIndex}>
                  <div className="d-flex justify-content-between flex-wrap my-2">
                    <h5>{program.name}</h5>
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleOpenModal(school, program);
                      }}
                    >
                      Open Enquiry Form
                    </Button>
                  </div>
                </div>
              ))}
              <hr />
            </div>
          );
        })}
    </div>
  ) : (
    <p>No data found.</p>
  )}
</Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

   

<Modal show={secondModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>Enquiry Form</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {successMessage ? (
      <div className="alert alert-success">{successMessage}</div>
    ) : (
      <Form onSubmit={submitNewEnquiryForm}>
        {selectedSchool && (
          <div>
            <Form.Group className="mb-3" controlId="formFieldName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="parentSchool"
                value={selectedSchool}
                onChange={handleNameSelect}
                required // Add this line
              />
            </Form.Group>

            {selectedProgram && (
              <div className="mb-3">
                <Form.Group controlId="formFieldProgramName">
                  <Form.Label>Program Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the program name"
                    name="parentProgram"
                    value={selectedProgram}
                    onChange={handleProgramNameSelect}
                    required // Add this line
                  />
                </Form.Group>
              </div>
            )}

            <div className="mb-3">
              <Form.Group controlId="formFieldCustomerName">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  name="customer_name"
                  placeholder="Enter your name"
                  value={formData.customer_name}
                  onChange={handleInputChange}
                  required // Add this line
                />
              </Form.Group>
            </div>

            <div className="mb-3">
              <Form.Group controlId="formFieldCustomerPhone">
                <Form.Label>Your Mobile No</Form.Label>
                <Form.Control
                  type="text"
                  name="customer_phone"
                  placeholder="Enter your mobile number"
                  value={formData.customer_phone}
                  onChange={handleInputChange}
                  required // Add this line
                />
              </Form.Group>
            </div>

            <div className="mb-3">
              <Form.Group controlId="formFieldCustomerEmail">
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="customer_email"
                  value={formData.customer_email}
                  onChange={handleInputChange}
                  required // Add this line
                />
              </Form.Group>
            </div>

            <div className="mb-3">
              <div>
                <Form.Label htmlFor="id_customer_query">Any Query</Form.Label>
              </div>
              <div>
                <Form.Control
                  as="textarea"
                  name="customer_query"
                  placeholder="Enter your query here (max 450 characters)"
                  className="form-control"
                  maxLength="500"
                  rows="3"
                  id="id_customer_query"
                  onChange={handleInputChange}
                  required // Add this line
                />
              </div>

              <div className="my-3">
                <div>
                  <Form.Label htmlFor="id_customer_query">Remarks</Form.Label>
                </div>
                <Form.Control
                  as="textarea"
                  name="remarks"
                  placeholder="Enter your query here (max 450 characters)"
                  className="form-control"
                  maxLength="500"
                  rows="3"
                  id="id_customer_query"
                  onChange={handleInputChange}
                  required // Add this line
                />
              </div>
            </div>

            <hr />
          </div>
        )}

        <div className="d-flex justify-content-between my-3">
          <button type="submit" className="btn bg-success text-white px-4">
            Submit
          </button>
          <Button variant="danger" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </Form>
    )}
  </Modal.Body>
</Modal>




    </>
  );
}
