import React from 'react';
import * as ReactDOM from "react-dom/client";
import './layouts/header'

import { App } from './App';
import { BrowserRouter } from 'react-router-dom';

// import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>

    <App />  
    </BrowserRouter>
  
  </React.StrictMode>
);

  