// @flow
import * as React from "react";
import stemojt from "../static/images/stem-ojt.jpg";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
import { NavLink } from "react-router-dom";

export function STEMOJTPprogram() {
  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Image
              src={stemojt}
              rounded
              className="img-fluid my-2"
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-sm-12">
            <h1 className="h2 fw-600 text-green mt-3">
              STEM OJT Program
            </h1>
            <p>
              The Science, Technology, Engineering and Mathematics (STEM) OJT Program offers candidates an opportunity to gain professional work experience within these specialized fields. This program is designed for students and recent graduates who want to further develop their skills and knowledge in a professional working.
            </p>

            <h2 className="h2 fw-600 text-green">PROGRAM DETAILS:</h2>
            <ul>
              <li>Tailored training related to your skills and academic background</li>
              <li>12 – 26 weeks, full-time, non-paid training positions</li>
              <li>Access to over 8,000 companies Australia wide</li>
              <li>Start every Monday</li>
              <li>407 Training Visa Sponsorship</li>
              <li>Flexibility with location is required</li>
              <li>Academic credit available if required</li>
              <li>Fields: STEM fields</li>
            </ul>

            <h2 className="h2 fw-600 text-green">ELIGIBILITY</h2>
            <ul>
              <li>Aged 18-40</li>
              <li>The OJT field requested is directly related to your educational background</li>
              <li>Currently enrolled in a tertiary course (bachelor degree or above), or</li>
              <li>A recent graduate within 12 months (at the time of visa submission) of course completion (bachelor degree or above)</li>
              <li>Upper intermediate-advanced English language skills (equivalent to IELTS 5.5)</li>
              <li>If you hold an Australian Visa with full work rights or are applying for an Australian Visa with full work rights, we offer flexibility in regards to eligibility criteria</li>
            </ul>

            <h2 className="h2 fw-600 text-green">START DATES AND LEAD TIME</h2>
            <p>
              Candidates have the flexibility of choosing their preferred start dates (allow for 3-4 month lead time):
            </p>
            <ul>
              <li>4 months lead time for 407 Training Visa candidates</li>
              <li>3 months lead time for candidates with proof of suitable working visas (e.g. Working Holiday Visa)</li>
              <li>This can be flexible if you are applying for an existing vacancy.</li>
            </ul>

            <h2 className="h2 fw-600 text-green">VISA SPONSORSHIP</h2>
            <p>
              The Department of Home Affairs has approved Australian OJT as a Sponsor for the Subclass 407 Training visa. will assess your eligibility and provide advice on the required documentation to support your visa application.
            </p>

            <h2 className="h2 fw-600 text-green">APPLICATION PROCESS</h2>
            <ol>
              <li>
                <strong>Enquiry and Assessment:</strong> Complete the online form and submit initial documentation to find out whether you are eligible for the program.
              </li>
              <li>
                <strong>Application & Processing:</strong> Eligible candidates will be requested to complete and return the application form along with the supporting documents and application fee. You will then provide us an application video after which Australian Host Organisation will negotiate your OJT details.
              </li>
              <li>
                <strong>Confirmation & Payment:</strong> Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> will arrange an interview for you with the Host Organisation after which the OJT will be confirmed through a formal Training Agreement.
              </li>
              <li>
                <strong>Visa & Travel Arrangements:</strong> Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> to negotiate with The Department of Home Affairs regarding visa processing for you. Or, you can make alternative arrangements (e.g. Working Holiday Visa). You are to confirm with Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> your arrival details.
              </li>
              <li>
                <strong>Program Participation:</strong> Congratulations! You are about to embark on an incredible journey. You will have an orientation when you arrive in the Pacific Islands. Once you begin your OJT, we will be asking for your continued feedback and continue to provide you with support.
              </li>
            </ol>
            <div className="pb-3"> 
              <i >
                *Additional programs are available. Please contact us for further details related to <NavLink className="fw-500" to="/contact-us">
                  STEM Vacancies!
                </NavLink>
              </i>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
