import React, { useEffect, useState } from "react";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";

// Function to format the date
const formatDate = (rawDate) => {
  if (!rawDate) return ""; 
  const formattedDate = new Date(rawDate).toDateString();
  return formattedDate;
};

export default function Discuss() {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [email, setEmail] = useState("");

  const [error, setError] = useState(null);

  const [verified, setVerified] = useState(false);
  const [questionContent, setQuestionContent] = useState("");
  const [statusData, setstatusData] = useState("");
  const [answerContent, setAnswerContent] = useState("");
  const [messageName, setMessageName] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [NewValue, setNewValue] = useState("");

  const [setanswerDbvalue, setanswerDb] = useState("");
  const [setparentQuestionanswervalue, setparentQuestionanswer] = useState("");
  const [setanswerStatusvalue, setanswerStatus] = useState("");
  const [setparentUserAnswervalue, setparentUserAnswer] = useState("");
  const [Setsignupmessage, Setsignup] = useState("");
  
  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the Discuss Questions API
        const responseQuestions = await fetch(
          "https://anhad.pythonanywhere.com/api/discuss-questions/"
        );
        const dataQuestions = await responseQuestions.json();
        //console.log('Fetched questions:', dataQuestions);
        setQuestions(dataQuestions);

        // Fetch data from the Discuss Answers API
        const responseAnswers = await fetch(
          "https://anhad.pythonanywhere.com/api/discuss-answers/"
        );
        const dataAnswers = await responseAnswers.json();
        const myvalue = dataAnswers.some(
          (answer) => answer.parentUser === messageName
        );

        console.log("$$$$$$$$$$$$$$$$$$$$$", myvalue);
        setAnswers(dataAnswers);

        // Fetch data from the Discuss Users API
        const responseUsers = await fetch(
          "https://anhad.pythonanywhere.com/api/discuss-users/"
        );
        const dataUsers = await responseUsers.json();
        // console.log('Fetched users:', dataUsers);
        setUsers(dataUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures the effect runs once after the initial render

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://anhad.pythonanywhere.com/api/discuss-users/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        Setsignup('Form submitted successfully');
        // console.log('Form submitted successfully');
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      // console.error('Error submitting form:', error);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleQuestionChange = (e) => {
    setQuestionContent(e.target.value); // Changed name
  };

  const handleMessageName = (e) => {
    // Changed name
    setMessageName(e.target.value);
  };

  const handleStatus = (e) => {
    loginStatus(e.target.value);
  };

  const handleAnswerChange = (e) => {
    setError(e.target.value); // Changed name
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://anhad.pythonanywhere.com/api/discuss-loginview/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ "qp[e]": email }),
        }
      );

      if (!response.ok) {
        throw new Error(`https error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Handle form submission response
      if (data.status === "verified") {
        setVerified(true);
        setError(null);
      } else if (data.status === "error" && data.message.includes("verified")) {
        // console.log("myall data:",data)
        const discussionUserStatus =
          data.message[data.message.indexOf("verified") + 2];
        // console.log("my id",discussionUserStatus)
        setLoginStatus(discussionUserStatus);

        setVerified(true);
        setError(data.message[0]);

        const userNameIndex = data.message.indexOf("verified") + 1;
        if (userNameIndex < data.message.length) {
          const userName = data.message[userNameIndex];
          setNewValue(userName);

          setMessageName(userName);
        }
      } else if (
        data.status === "error" &&
        data.message.includes("not-verified")
      ) {
        setVerified(false);
        setError(data.message[0]);
      } else {
        setVerified(false);
        setError("Account not verified");
      }

    } catch (error) {
      // console.error('Error submitting email:', error);
    }
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://anhad.pythonanywhere.com/api/discuss-questions/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if required
          },
          body: JSON.stringify({
            question: questionContent,
            parentUser: messageName, // Include 'parentUser' field
            status: loginStatus, // Include 'status' field
          }),
        }
      );

      if (response.ok) {
        setSuccessMessage("Question submitted successfully");
      } else {
        setSuccessMessage("Question submission failed");

        // Handle the error case if needed
      }
    } catch (error) {
      console.error("Error submitting question:", error);
      setSuccessMessage("Question submission failed");
    }
  };

  const handleAnswerSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://anhad.pythonanywhere.com/api/discuss-answers/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if required
          },
          body: JSON.stringify({
            question: questionContent,
            parentUser: messageName, // Include 'parentUser' field
            status: loginStatus, // Include 'status' field
          }),
        }
      );

      if (response.ok) {
        setSuccessMessage("Question submitted successfully");
      } else {
        setSuccessMessage("Question submission failed");

        // Handle the error case if needed
      }
    } catch (error) {
      console.error("Error submitting question:", error);
      setSuccessMessage("Question submission failed");
    }
  };

  useEffect(() => {
    if (error) {
      const timeoutId = setTimeout(clearError, 3000); // Adjust the time (in milliseconds) as needed
      return () => clearTimeout(timeoutId); // Clear the timeout when the component unmounts or when the effect re-runs
    }
  }, [error]);

  const userAnswer = (e) => {
    setanswerDb(e.target.value); // Changed name
  };

  const userParentQueation = (e) => {
    // Changed name
    setparentQuestionanswer(e.target.value);
  };

  const userStatus = (e) => {
    setanswerStatus(e.target.value);
  };

  const userParentuser = (e) => {
    setparentUserAnswer(e.target.value); // Changed name
  };

  return (
    <>
      <MyNav />
      <div className="container">
<div className="row">
    <div className="col-12 justify-content-end d-flex my-4">        
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      Ask Question
      </button>
    </div>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">


      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <div className="row w-100">
          <div className="col-6">  
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Sign Up</button>
            </li>
          </div>
          <div className="col-6">
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Log In</button>
            </li>
          </div>
        </div>
          
      </ul>
<div class="tab-content" id="pills-tabContent">

          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">

                              <form onSubmit={handleSubmit}>
                                <label>
                                  Name:
                                  <input
                                    name="name"
                                    className="form-control"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                </label>
                                <br />
                                <label>
                                  Email:
                                  <input
                                    name="email"
                                    className="form-control"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                </label>
                                <br />
                                <button type="submit" className="btn px-4 btn-success my-2">Submit</button>
                              </form>
                            <p>{Setsignupmessage}</p>
                      
          </div>


    
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                <form onSubmit={handleFormSubmit}>
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                Email address:
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                placeholder="Email"
                                onChange={handleEmailChange}
                              />
                            </div>

                            <button type="submit" className="btn btn-success">
                              Submit
                            </button>
                </form>
                  
                    {error && (
                      <div className="alert alert-danger mt-3" role="alert">
                        {error}
                      </div>
                    )}

                    {verified && (
                      <div>
                        <form onSubmit={handleQuestionSubmit}>
                          <div className="mb-3">
                            <label htmlFor="question" className="form-label">
                              Question:
                            </label>
                            <textarea
                              className="form-control"
                              id="question"
                              name="question"
                              value={questionContent}
                              onChange={handleQuestionChange}
                            />

                            <input
                              type="hidden"
                              name="parentUser"
                              value={messageName}
                              onChange={handleMessageName}
                            />

                            <input
                              type="hidden"
                              name="status"
                              value={loginStatus}
                              onChange={handleStatus}
                            />
                          </div>
                          <button type="submit" className="btn btn-primary">
                            Submit Question
                          </button>
                          {successMessage && <p>{successMessage}</p>}
                        </form>
            {/* 
                        <form onSubmit={handleAnswerSubmit}>
                          <div className="mb-3">
                            <label htmlFor="answer" className="form-label">
                              Answer:
                            </label>
                            <textarea
                              className="form-control"
                              id="answer"
                              value={answerContent}
                              onChange={userAnswer}
                            />

                            <input
                              type="hidden"
                              name="status"
                              value={loginStatus}
                              onChange={userStatus}
                            />

                            <input
                              type="text"
                              name="parentQuestion"
                              value={messageName}
                              onChange={userParentQueation}
                            />

                            <input
                              type="hidden"
                              name="parentUser"
                              value={messageName}
                              onChange={userParentuser}
                            />
                          </div>
                          <button type="submit" className="btn btn-primary">
                            Submit Answer
                          </button>
                        </form> */}

                      </div>
                    )}
          </div>
     
    
  
  </div>
      
</div>




           
      </div>

    </div>
  </div>
</div>

           
           
          
            
   
      <div className="container py-4">
        <h1>Discussion Questions</h1>
        <div className="row">
          <div className="col-sm-12">
            <div className="accordion bg-white" id="accordionExample">
              {questions.map((question, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button bg-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index}`}
                    >
                      {question.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        {/* Filter answers for the current question */}
                        {answers
                          .filter((answer) => answer.parentQuestion === question.id)
                          .map((answer) => (
                            <li key={answer.id}>
                              {answer.answer}
                              <span className="fw-bold">
                                {" "}
                                -{" "}
                                {
                                  users.find(
                                    (user) => user.id === answer.parentUser
                                  )?.name
                                }
                                ,{" "}
                                {formatDate(
                                  users.find(
                                    (user) => user.id === answer.parentUser
                                  )?.registration_date
                                )}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
