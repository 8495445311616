import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import CourseDetail from './pages/CourseDetail';
import Discuss from './pages/Discuss';
import { Home } from './pages/Home';
import { StudentLoan } from './pages/StudentLoan';
import { StudyInAus } from './pages/StudyInAus';
import { StudyInCanada } from './pages/StudyInCanada';
import { StudyInUSA } from './pages/StudyInUSA';
import { StudyInUk } from './pages/StudyInUk';
import { WorkInAus } from './pages/WorkInAus';

import { ProfessionalOJTProgram } from './pages/ProfessionalOJTProgram';
import { Earlychildhoodprogram } from './pages/Earlychildhoodprogram';
import { STEMOJTPprogram } from './pages/STEMOJTPprogram';
import { Stipendojtprogram } from './pages/Stipendojtprogram';
import { Virtualojtprogram } from './pages/Virtualojtprogram';

export const App = () => {
  
const { pathname } = useLocation();

useEffect(()=>{
window.scrollTo(0,0)
},[pathname])

  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/study-in-canada" element={<StudyInCanada />} />
        <Route path="/study-in-uk" element={<StudyInUk />} />
        <Route path="/study-in-USA" element={<StudyInUSA />} />
        <Route path="/study-in-australia" element={<StudyInAus />} />
        <Route path="/mydata" element={<CourseDetail />} />
        <Route path="/discuss" element={<Discuss />} />
        <Route path="/student-loan" element={<StudentLoan />} />

        <Route path="/hospitalityojtprogram" element={<WorkInAus />} />
        <Route path="/earlychildhoodprogram" element={<Earlychildhoodprogram />} />
        <Route path="/professionalojtprogram" element={<ProfessionalOJTProgram />} />
        <Route path="/stemojtprogram" element={<STEMOJTPprogram  />} />
        <Route path="/stipendojtprogram" element={<Stipendojtprogram  />} />
        <Route path="/virtualojtprogram" element={<Virtualojtprogram  />} />



        <Route path="*" element={<Home />} />
      </Routes>
  );
};
