// @flow
import * as React from "react";
import workink from "../static/images/hospitality.jpg";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
export function WorkInAus() {
  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Image
              src={workink}
              rounded
              className="img-fluid my-2"
              style={{width: "100%",maxHeight:"585px",objectFit:"cover"}}
            />
          </div>

          <div className="col-sm-12">
            <h1 className="h2 fw-600 text-green mt-3">
              AUSTRALIAN OJT Program
            </h1>
            <p>
              Australian OJT Program was founded in 1998 to provide respon- sive
              on-the-job training to meet international demand from
              universities, students and graduates. Australian OJT caters for
              students and graduates from over 90 countries, providing oppor-
              tunities with some 7,000+ Australian host companies and Govern-
              ments Departments in all academic disciplines.
            </p>

            <h1 className="h2 fw-600 text-green">HOSPITALITY OJT PROGRAM</h1>
            <ul>
              <li>
                Paid training positions available within the Hospitality sector
                throughout Australia
              </li>
              <li>
                Australian Award Wage paid training positions – Minimum $23 
                per hour in Food & Beverage, Front Office and Food Production
                Departments
              </li>
              <li>Duration is from 6-12 months.</li>
              <li>
                {" "}
                Candidates must be flexible to travel to any Australian
                location, academic credit available.
              </li>
              <li>
                Apply up to 4–6 months in advance, pending available vacancies
                and visas
              </li>
              <li>
                {" "}
                Specific eligibility requirements including prior work experi
                ence (6 months) in selected field and minimum 12 months tertiary
                level study obtained within the past18 months.
              </li>
            </ul>

            <h2 className="h2 fw-600 text-green">HOST ORGANISATIONS</h2>
            <p>
              Following a comprehensive interview process, you will be allocated
              to one of 1,700 host companies throughout Austra- lia. Selections
              are made in all cases based on best available fit between the host
              company and your skill-set or academic background.
            </p>
            <p>Host companies that AI have access to include:</p>
            <ul>
              <li>Hyatt Hotels</li>
              <li>Marriott Hotels</li>
              <li>Accor Group</li>
              <li>Intercontinental Hotel Group</li>
              <li>Hilton Hotels</li>
              <li>Sheraton Hotels</li>
              <li>
                Resorts in the Whitsundays (Great Barrier Reef ) and more…
              </li>
              <li>Specific properties cannot be guarantee</li>
            </ul>

            <h3 className="h2 fw-600 text-green">ELIGIBILITY</h3>

            <div>Age: Between 18–30</div>
            <div>
              Students: Enrolled in a full time Hospitality or Culinary
              management tertiary course (minimum Diploma level).
            </div>
            <div>
              Graduates: A recent graduate (of a full time Hospitality or
              Culinary management tertiary course) within 18 months of course
              completion.
            </div>
            <div>
              Work Experience: Minimum 6 months full time (or 12 months part
              time) in the selected OJT department
            </div>
            <div>
              OJT Field: Studies and experience must be related to the nominated
              OJT department.
            </div>
            <div>
              Preference: Currently working in the selected OJT depart- ment.
            </div>

            <div>
              Flexible: Willing to work in any Australian city, including rural
              and remote locations.
            </div>

            <h3 className="h2 fw-600 text-green my-3">BENEFITS OF OJT</h3>
  
            <ul>
                <li> Gain practical experience in the Australian
workplace.</li>
                <li>Receive training within your area of study.</li>
                <li>Expand your network of professional contacts.</li>
                <li>Develop an understanding of the international
workplac</li>
                <li>Cultivate industry specific terminology.</li>
                <li>Improve workplace communication skills.</li>
                <li>Gain a competitive edge in the job market.</li>
                <li>Create memories that will last a lifetime.</li>
                <li>Develop soft skills that are vital for career success.</li>
            </ul>


            <h3 className="h2 fw-600 text-green my-3">VISA OPTIONS</h3>
            <p>Australian OJTs is an approved sponsor with the Department of
Immigration and Border Protection and provides assistance to
all eligible candidates with the 407Training Visa.</p>

<p>You may also complete an OJT on one of the following visas:</p>
<ul>    <li>Working Holiday  Work & Holiday Visa.</li>
    <li>Skilled Graduate Visa</li>
</ul>

<h3 className="h2 fw-600 text-green my-3">DOCUMENTATION</h3>

<p>
We require the following documents in order to assess your
eligibility
</p>


<ul>
    <li>Resume (in Word)</li>
    <li>Letter of Objectives (in Word)</li>
    <li>Colour copy of your passport</li>
    <li>Copy of your most current academic transcripts that show your
grades</li>
    <li>Reference Letters from your previous work experiences
Template documents and guides are available. Additional
documents are required for visa processing</li>
</ul>


<h3 className="h2 fw-600 text-green my-3">After Completion of OJT</h3>
<p>
After completing OJT successfully you may be eligible to apply
for further visas (on and off-shore) to migrate to Australia on
temporary or permanent basis. Our company Anhad Immigration will
assist you in this regard to explore suitable Visa options for you
</p>

<h3 className="h2 fw-600 text-green my-3">Note</h3>
<ul style={{fontSize:'13px'}}>
    <li>Candidate must have Medical Insurance before coming to Australia as per government rules and Medical
facilities are expensive in Australia</li>
    <li>The RSA (Responsible Service of Alcohol) course is pre-requisite for Hospitality OJT (F&B) and must be
completed prior to commencing the OJT.</li>
    <li>You will also get extra 9% of superannuation (same like PF) of your total income. This will not deduct from
your salary as employer will pay extra as per Australian Rule.</li>
    <li>Airfare and any other living expenses are not included in the fees.</li>
    <li>All Income and expenses calculated above are for Hospitality OJT (52 weeks) and is used for only example.</li>
    <li>The currency rate varies time to time; current rate will apply at the time of payment or clearing invoice.</li>
    <li>Medicals expenses to be paid by applicants.</li>
    <li>Duration of placement time can be between 12 weeks to 52 weeks. It may take longer depend on applicant
profile & jobs availabilities</li>
    <li>Refund Policies & T&C will work as on Australian OJT policies.</li>
</ul>

















          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
