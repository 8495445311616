// @flow
import * as React from "react";
import workInUK from "../static/images/study/study-in-uk.png";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
export function StudyInUk() {
  return (
    <>
      <MyNav />
      <div className="container my-4">
        <div className="row">
          <div className="col-sm-12">
            <div className="display-6 fw-600 red">Studying in UK</div>
            <p className="my-2">
              United Kingdom has one of the largest and leading educational
              institutions. It is the leading educational destination of the
              world. There are the leading names like Oxford and Cambridge that
              produce the best minds in the world. Rise en Shine helps students
              to gain entry to the UK. This helps to give you the right
              experience and gain stress free entry. UK is the second best
              destination of the world for international students. It's degrees
              are recognized worldwide.
            </p>
          </div>
          <div className="col-sm-12">
            <Image
              src={workInUK}
              rounded
              className="img-fluid my-2 w-100"
              style={{}}
            />
          </div>

          <div className="col-sm-12">
          <h2 className="display-6 fw-600 red my-3">
          Why study in UK?
          </h2>
            <p>
              UK carries top reputation for academic excellence. Also, it has
              the best quality of students present. Therefore, it is an ideal
              destination for getting the best results. Likewise, more than one
              million students study in UK.{" "}
            </p>
            <ul>
              <li>Affordable education</li>
              <li>Internationally recognized courses</li>
              <li>World class educational experience</li>
              <li>Innovative research opportunities</li>
              <li>Healthy and safe environment for students</li>
              <li>Scholarships and financial aid</li>
              <li>Multi-cultural environment</li>
              <li>Best place to learn and study English</li>
              <li>More than 50k courses in 25+ subject areas</li>
              <li>Short term courses that are highly affordable</li>
              <li>Work while studying</li>
              <li>Affordable accomodation plans</li>
            </ul>
          
              <h2 className="h3 fw-600 text-green mt-4">
                Upcoming intakes in UK
              </h2>
            <p>
              Colleges and universities in UK have 3 intakes. These are called
              as terms.
            </p>
            <ul>
              <li>
                Term 1 - Start of intake - September. This is the most popular
                one
              </li>
              <li>Term 2- Start of intake - February</li>
              <li>Term 3 - Start of intake - May/ June for selected courses</li>
            </ul>
            <h2 className="h3 fw-600 text-green mt-4">
              Work authorization for students in UK
            </h2>
            <ul>
              <li>Student must be minimum 18 years of age</li>
              <li>
                International students who study full time can work for 20 hours
                per week.
              </li>
              <li>Full time work during holidays is possible</li>
              <li>
                Clearly defined semesters along with specific break period
              </li>
              <li>Work during break time is allowed</li>
            </ul>
            In case of spouse -
            <ul>
              <li>Spouse or dependent can work full time</li>
              <li>Working as doctor is not permitted</li>
              <li>For highly skilled spouse, tier-2 visa is allowed</li>
            </ul>

            <h2 className="h3 fw-600 text-green mt-4">
              UK student visa requirements
            </h2>
            <p>
            For getting tier 4 student visa, focus on following documents -

            </p>
            <ul>
              <li>Affordable education</li>
              <li>Internationally recognized courses</li>
              <li>World class educational experience</li>
              <li>Innovative research opportunities</li>
              <li>Healthy and safe environment for students</li>
              <li>Scholarships and financial aid</li>
              <li>Multi-cultural environment</li>
              <li>Best place to learn and study English</li>
              <li>More than 50k courses in 25+ subject areas</li>
              <li>Short term courses that are highly affordable</li>
              <li>Work while studying</li>
              <li>Affordable accomodation plans</li>
            </ul>


          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
