// @flow
import * as React from "react";
import professionalojtprogram from "../static/images/professionalojtprogram.jpeg";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";

export function ProfessionalOJTProgram() {
  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Image
              src={professionalojtprogram}
              rounded
              className="img-fluid my-2"
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-sm-12">
            <h1 className="h2 fw-600 text-green mt-3">
              Professional OJT Program
            </h1>
            <p>
              The Professional OJT Program offers candidates an opportunity to gain professional work experience and improve career opportunities in almost all academic fields and a wide range of industries.
            </p>
            <p>
              It is aimed at candidates seeking work experience positions in Australia. An OJT is a great way to gain exposure into the professional work environment and develop professional workplace skills.
            </p>

            <h2 className="h2 fw-600 text-green">PROGRAM DETAILS:</h2>
            <ul>
              <li>Tailored training related to your skills and academic background</li>
              <li>6 – 26 weeks, full-time, non-paid training positions</li>
              <li>Access to over 10,000 companies Australia wide</li>
              <li>Start every Monday</li>
              <li>407 Training Sponsorship</li>
              <li>Flexibility with location is required in some fields</li>
              <li>Academic credit available if required</li>
              <li>Fields: most academic disciplines</li>
              <li>Certain fields may require extra lead time and/or additional requirements, such as portfolios.</li>
              <li>Positions in the Health Industry for doctors, nurses, dentists etc. are not available due to Government restrictions.</li>
            </ul>

            <h2 className="h2 fw-600 text-green">PART-TIME OJT’S:</h2>
            <p>
              If you are not able to join full-time basis but still would like to boost your career and give yourself a competitive advantage, this is an option for you.
              You can study and work at the same time!
            </p>
            <p>
              Aimed at candidates already in Australia on a Student Visa. If you do hold another visa and wish to be completing a part-time program.
            </p>
            <p>
              This program is available in the following fields: Business Admin, Events, HR, Accounting, and Marketing.
            </p>
            <p>
              Candidates can work 20 hours per week (for 12 weeks minimum) on set days and/or during set hours.
            </p>

            <h2 className="h2 fw-600 text-green">ELIGIBILITY</h2>
            <ul>
              <li>Aged 18-30</li>
              <li>The OJT field requested is directly related to your educational background</li>
              <li>If you are a student from overseas: Currently enrolled in a tertiary course (bachelor degree or above) and completing the OJT for academic credit required by the University/School</li>
              <li>If you are a student in Australia: Currently enrolled in a tertiary course (bachelor degree or above)</li>
              <li>OR</li>
              <li>A recent graduate within 12 months (at the time of visa submission) of course completion (bachelor degree or above)</li>
              <li>Upper intermediate – advanced English language skills (equivalent to IELTS 5.5)</li>
            </ul>

            <h2 className="h2 fw-600 text-green">VISA SPONSORSHIP</h2>
            <p>
              The Department of Home Affairs has approved Australian OJT as a Sponsor for the Subclass 407 Training visa. We will assess your eligibility and provide advice on the required documentation to support your visa application.
            </p>

            <h2 className="h2 fw-600 text-green">APPLICATION PROCESS</h2>
            <ol>
              <li>
                <strong>Enquiry and Assessment:</strong> Complete the online form and submit initial documentation to find out whether you are eligible for the program.
              </li>
              <li>
                <strong>Application & Processing:</strong> Eligible candidates will be requested to complete and return the application form along with the supporting documents and application fee. You will then provide us an application video after which Australian Host Organisation will negotiate your OJT details.
              </li>
              <li>
                <strong>Confirmation & Payment:</strong> Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> will arrange an interview for you with the Host Organisation after which the OJT will be confirmed through a formal Training Agreement.
              </li>
              <li>
                <strong>Visa & Travel Arrangements:</strong> Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> to negotiate with The Department of Home Affairs regarding visa processing for you. Or, you can make alternative arrangements (e.g. Working Holiday Visa). You are to confirm with Australian <a href="https://www.mara.gov.au/#:~:text=Office%20of%20the%20Migration%20Agents%20Registration%20Authority" className="fw-500" target="__blank"> Office of the Migration Agents Registration Authority </a> your arrival details.
              </li>
              <li>
                <strong>Program Participation:</strong> Congratulations! You are about to embark on an incredible journey. You will have an orientation when you arrive in the Pacific Islands. Once you begin your OJT we will be asking for your continued feedback and continue to provide you with support.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
