import * as React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import Newlog from "../static/images/newlogo.png";
import { NavLink } from "react-router-dom";

export const MyNav = () => {
  return (
    <>
      <Container className="d-none d-lg-block">
        <div className="col-sm-12 text-center">
          <Image
            src={Newlog}
            rounded
            className="img-fluid "
            style={{ width: "300px" }}
          />
        </div>
      </Container>
      <Navbar expand="lg" className="">
        <Container>
          <Navbar.Brand to="/" className="d-block d-lg-none">
            <Image
              src={Newlog}
              rounded
              className="img-fluid d-block d-lg-none"
              style={{ width: "155px" }}
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto d-flex w-100 align-items-center justify-content-between"
              style={{ fontWeight: "600" }}
            >
              <NavLink
                className="nav-custome-color px-4 py-1 text-decoration-none"
                to="/"
                style={({ isActive }) => {
                  return { backgroundColor: isActive ? "" : "" };
                }}
              >
                Home
              </NavLink>
              <NavLink className="nav-custome-color px-3 text-decoration-none px-4 py-1" to="/about-us">
                About Us
              </NavLink>
              {/* <NavDropdown title="Study" className="text-center custome-nav-dropdown nav text-decoration-none p-0" id="basic-nav-dropdown" style={{ padding:'15px' }}>
                <NavDropdown.Item as={NavLink} to="/study-in-uk" className="my-2 nav-custome-color text-decoration-none">
                  Study In UK
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/study-in-USA" className="my-2 nav-custome-color text-decoration-none" >
                  Study In USA
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/study-in-australia" className="my-2 nav-custome-color text-decoration-none" >
                  Study In Australia
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/study-in-canada" className="my-2 nav-custome-color text-decoration-none" >
                  Study In Canada
                </NavDropdown.Item>
              </NavDropdown> */}

          <div className="nav-dropdown">
            <div className="text-center nav-dropdown-toggle custome-nav-dropdown nav text-decoration-none px-4 py-1 nav-custome-color" style={{ padding: '15px' }}>
              Study
            </div>
            <div className="nav-dropdown-menu px-2 py-1"  style={{ zIndex: 1 }}>
              <NavDropdown.Item as={NavLink} to="/study-in-uk" className="nav-dropdown-item text-decoration-none">
                Study In UK
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/study-in-USA" className="nav-dropdown-item text-decoration-none">
                Study In USA
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/study-in-australia" className="nav-dropdown-item text-decoration-none">
                Study In Australia
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/study-in-canada" className="nav-dropdown-item text-decoration-none">
                Study In Canada
              </NavDropdown.Item>
            </div>
          </div>

          <div className="nav-dropdown">
            <div className="text-center nav-dropdown-toggle custome-nav-dropdown nav text-decoration-none px-4 py-1 nav-custome-color" style={{ padding: '15px' }}>
             Work In Australia
            </div>
            <div className="nav-dropdown-menu px-2 py-1">
              <NavDropdown.Item as={NavLink} to="/hospitalityojtprogram" className="nav-dropdown-item text-decoration-none">
                 Hospitality OJT Program
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/earlychildhoodprogram" className="nav-dropdown-item text-decoration-none">
              Early Childhood Program 
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/professionalojtprogram" className="nav-dropdown-item text-decoration-none">
              Professional OJT Program 
              </NavDropdown.Item>
             
              <NavDropdown.Item as={NavLink} to="/stipendojtprogram" className="nav-dropdown-item text-decoration-none">
              STIPEND OJT PROGRAM 
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/stemojtprogram" className="nav-dropdown-item text-decoration-none">
               STEM OJT PROGRAM 
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/virtualojtprogram" className="nav-dropdown-item text-decoration-none">
                Virtual OJT Program
              </NavDropdown.Item>
             
            </div>
          </div>



              {/* <NavLink
                className="nav-custome-color px-4 py-1 text-decoration-none"
                to="/work-in-australia"
              >
                Work In Australia
              </NavLink> */}
              <NavLink className="nav-custome-color px-4 py-1 text-decoration-none" to="/contact-us">
                Contact Us
              </NavLink>
              <NavLink className="nav-custome-color px-4 py-1 text-decoration-none" to="/student-loan">
                Student Loan
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
