import React from 'react'
import { Link } from "react-router-dom";

export default function DiscussComp() {
  return (
    <div className='contact-bg'>
        
        <div className='container'>
            <div className='d-flex justify-content-center justify-content-sm-end justify-content-lg-between align-items-center flex-wrap py-5'>
                <h4 className='fw-600 text-white'>FEEL FREE TO SHARE AND DISSCUSS ANY KIND OF QUERIES</h4>
                <div className='text-center mt-3 mt-lg-0'>
                    <Link to="/discuss" className="btn btn-info px-5 py-2 text-white text-decoration-none">
                    Discuss
                </Link>
                </div>
            </div>            
        </div>
    </div>

  )
}
