// Home.js
import React from "react";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Heroimg from "../static/images/index-page/hero-slider-img.png";
import Ele1 from "../static/images/element.png";
import Ele2 from "../static/images/element-new.png";

import Ele3 from "../static/images/element-2.png";
import canda from "../static/images/index-page/canda.png";
import us from "../static/images/index-page/us.png";
import aus from "../static/images/index-page/aus.png";
import uk from "../static/images/index-page/uk.png";
import workinaus from "../static/images/index-page/work-in-aus.png";
import query from "../static/images/index-page/query.png";
import info from "../static/images/index-page/info.png";
import connectwith from "../static/images/connectwith.jpg";

import { Link } from "react-router-dom";
import { useState } from "react";
import CourseDetail from "./CourseDetail";
import Discuss from "./Discuss";
import DiscussComp from "./DiscussComp";

const Home = () => {
  const [textValue, setTextValue] = useState(""); // Initialize the text area value to an empty string
  const [successMessage, setSuccessMessage] = useState('');
  const [successconnectMessage, setConnectMessage] = useState('');

  const handleChange = (event) => {
    // Update the state with the new value of the text area
    setTextValue(event.target.value);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("Text Value Submitted:", textValue);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://anhad.pythonanywhere.com/api/message/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Message: textValue }),
      });

      if (response.ok) {
        setSuccessMessage("Message sent successfully!");
        setTextValue("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000); // Adjust the timeout as needed  
      } else {
        console.error("Error sending message:", response.statusText);
        const errorData = await response.json();
        console.error("Error data:", errorData);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    subject: "",
    Message: "",
  });

  const handleChangeConnect = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitConnect = async (e) => {
    e.preventDefault();
    console.log("Form submitted!");
    console.log(formData);

    try {
      const response = await fetch(
        "https://anhad.pythonanywhere.com/api/student-contacts/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {       
        setConnectMessage("Message sent successfully!");
        // You can reset the form fields here if needed
        setFormData({
          Name: "",
          Email: "",
          subject: "",
          Message: "",
        });
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000); 
      } else {
        console.error("Error sending form data:", response.statusText);
        const errorData = await response.json(); // Log the error response data
        console.error("Error data:", errorData);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <>
      <MyNav />

      <section className="my-1 my-lg-5">
        <div className="container">
          <div className="row pt-4">
            <div className="col-12 col-lg-6">
              <div
                className="display-5 fw-600 my-2"
                style={{ color: "#10788F" }}
              >
                Explore the Spectrum of Educational Success
              </div>
              <div className="d-block d-lg-none text-center">
                <Image
                  src={Heroimg}
                  rounded
                  className="img-fluid my-0 my-sm-0 Heroimg-index"
                  style={{}}
                />
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center justify-content-lg-start align-items-lg-start">
                
                <CourseDetail />
              </div>
            </div>
            <div className="col-12 col-lg-6 d-none d-lg-block">
              <div class="d-flex justify-content-center align-items-center">
                <Image
                  src={Heroimg}
                  rounded
                  className="img-fluid my-2"
                  style={{ width: "450px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="row ">
            <div className="col-12">
              <div className="h1 text-center red my-4">About Us</div>
              <p>
                Anhad Immigration group is an educational advisory and
                consultancy service provider to students who wish to study in
                the UK, Canada, and the USA. At our organization, we aim to
                offer the students with concrete results for realizing their
                dreams of studying in international colleges. We also provide
                additional guidance and mentorship program at no additional cost
                in three phases-
              </p>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="d-flex justify-content-around">
            <div className="">
              <Image
                src={Ele2}
                rounded
                className="img-fluid my-2"
                style={{ width: "150px" }}
              />
            </div>
            <div className="">
              <Image
                src={Ele1}
                rounded
                className="img-fluid my-2"
                style={{ width: "150px" }}
              />
            </div>

            <div className="">
              <Image
                src={Ele3}
                rounded
                className="img-fluid my-2"
                style={{ width: "150px" }}
              />
            </div>
          </div>
        </div>

        <div className="container my-4">
          <div className="h1 text-center red my-2 my-lg-5 find-your-course">
            Find your Course
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-3 my-3 my-xl-0">
              <div className="fdf_card fdf-card-first p-3 rounded-3">
                <div className="text-center">
                  <Image
                    src={canda}
                    rounded
                    className="img-fluid my-2"
                    style={{}}
                  />
                </div>
                <h4 className="red text-center my-1">Study In Canada</h4>
                <p className="my-4" style={{ height: "170px" }}>
                  Canada is one of the best places in the world for higher
                  education. One gets world class education which is recognized
                  globally. University and colleges of Canada support
                </p>
                <Link to="/study-in-canada" className="text-decoration-none">
                  See More
                </Link>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 my-3 my-xl-0">
              <div className="fdf_card fdf-card-second p-3 rounded-3">
                <div className="text-center">
                  <Image
                    src={uk}
                    rounded
                    className="img-fluid my-2"
                    style={{}}
                  />
                </div>
                <h4 className="red text-center my-1">Study In UK</h4>
                <p className="my-4" style={{ height: "170px" }}>
                  United Kingdom has one of the largest and leading educational
                  institutions. It is the leading educational destination of the
                  world. There are the leading names
                </p>
                <Link to="/study-in-UK" className="text-decoration-none">
                  See More
                </Link>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 my-3 my-xl-0">
              <div className="fdf_card fdf-card-third p-3 rounded-3">
                <div className="text-center">
                  <Image
                    src={aus}
                    rounded
                    className="img-fluid my-2"
                    style={{}}
                  />
                </div>
                <h4 className="red text-center my-1">Study In Australia</h4>
                <p className="my-4" style={{ height: "170px" }}>
                  Australia is undeniably one of the most beautiful places on
                  earth where one dreams to study because of its spectacular
                  various Top universities, incredible nature, vibrant cities,
                  aninnumerable oceans. These are just
                </p>
                {/* <Link to="/work-in-australia" className="text-decoration-none">
                  See More
                </Link> */}
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 my-3 my-xl-0">
              <div className="fdf_card fdf-card-fourth p-3 rounded-3">
                <div className="text-center">
                  <Image
                    src={us}
                    rounded
                    className="img-fluid my-2"
                    style={{}}
                  />
                </div>
                <h4 className="red text-center my-1">Study In USA</h4>
                <p className="my-4" style={{ height: "170px" }}>
                  Canada has one of the largest and leading educational
                  institutions. It is the leading educational destination of the
                  world. There are the leading names
                </p>
                <Link to="/study-in-USA" className="text-decoration-none">
                  See More
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid my-5"
          style={{ backgroundColor: "#83BBED" }}
        >
          <div className="container py-5">
            <div className="row d-flex align-items-center">
              <h3 className="red text-center">Work in Australia</h3>
              <div className="col-sm-9">
                <p>
                  Australia is undeniably one of the most beautiful places on
                  earth where one dreams to study because of its spectacular
                  various Top universities, incredible nature, vibrant cities,
                  and innumerable oceans. These are just some of the peculiar
                  reasons why many international students' first choice is
                  Australia to study. However, Studying abroad may be slightly
                  daunting as most would relate, but the reward is in terms of
                  education as well as personal development. It is essentially
                  huge in terms of wealth, education, health and quality of
                  life, ranked as one of the best countries. Australia has
                  top-ranked institutions, globally recognized qualifications
                  and an abundance of scholarships when it comes to offering
                  spectacular education.
                </p>
                {/* <Link to="/work-in-australia" className="text-decoration-none">
                  See More
                </Link> */}
              </div>

              <div className="col-sm-3">
                <Image
                  src={workinaus}
                  rounded
                  className="img-fluid my-2"
                  style={{}}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row d-flex justify-content-between justify-content-xl-center my-5">
            <div className="col-12 col-lg-12 col-xl-10">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="d-flex gap-4 align-items-center justify-content-center justify-content-lg-start">
                    <Image
                      src={query}
                      rounded
                      className="flif-img img-fluid my-2"
                      style={{}}
                    />
                    <div className="display-5 display-sm-4 fw-600 red">
                      DO YOU <br />
                      HAVE A <br />
                      QUERY?
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
                  <form onSubmit={handleSubmit}>
                    <div>
                      <textarea
                        placeholder="Write a Query here"
                        className="form-control"
                        id="textArea"
                        name="Message"
                        value={textValue}
                        onChange={handleChange}
                        rows="8" 
                        cols="50"
                        required
                      />
                    </div>
                    <div className="text-end mt-4">
                      <button type="submit" className="btn btn-red px-4">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row my-5">
            <div className="col-12 col-lg-6">
              <div className="fdf_card px-3 px-sm-5 py-3 py-sm-5">
                <div className="display-6 fw-500 text-green">
                  Connect With Us
                </div>
                
                <form onSubmit={handleSubmitConnect}>
                  <div className="mt-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      id="Name"
                      name="Name" // Make sure it matches the Django model field name
                      value={formData.Name}
                      onChange={handleChangeConnect}
                      required
                    />
                  </div>

                  <div className="mt-4">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="email"
                      name="Email"
                      value={formData.Email}
                      onChange={handleChangeConnect}
                      required
                    />
                  </div>

                  <div className="mt-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChangeConnect}
                      required
                    />
                  </div>

                  <div className="mt-4">
                    <textarea
                      className="form-control"
                      id="message"
                      name="Message"
                      placeholder="Message"
                      value={formData.Message}
                      onChange={handleChangeConnect}
                      required
                    />
                  </div>

                  <div className="mt-4">
                    <button type="submit" className="btn btn-green px-5">
                      Send
                    </button>
                  </div>
                </form>

                  {successconnectMessage && (
                  <div className="alert alert-success my-2" role="alert">
                    {successconnectMessage}
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-lg-6 text-end d-flex justify-content-center align-items-center my-4 my-lg-0">
              <Image src={connectwith} rounded className="img-fluid" style={{height:'100%',width:'100%',objectFit:'cover' }} />
            </div>
          </div>
        </div>

      </section>
        <DiscussComp />
      <Footer />
    </>
  );
};

export { Home }; // Export Home as a named export
