// @flow
import * as React from "react";
import Studyinusa from "../static/images/study/study-in-usa.png";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";
export function StudyInUSA() {
  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Image
              src={Studyinusa}
              rounded
              className="img-fluid my-4"
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-sm-12">
            <div className="display-6 fw-600 red my-2">Why study in the USA?</div>
            <p>
              The USA gives the best learning experience to international
              students. As a result, the best ranking is available for the
              students to work internationally. It carries best coursework and
              learning environment. Students gain both theoretical knowledge and
              practical experience.
            </p>

           
            <ul>
              <li>Affordable education</li>
              <li>Internationally recognized courses</li>
              <li>World class educational experience</li>
              <li>Innovative research opportunities</li>
              <li>Healthy and safe environment for students</li>
              <li>Scholarships and financial aid</li>
              <li>Multi-cultural environment</li>
              <li>Best place to learn and study</li>
              <li>Internships</li>
              <li>Exciting learning outcomes</li>
            </ul>
            <h2 className="h2 fw-600 text-green">Cost of studying in USA</h2>
            <p>There are two types of universities -</p>
            <ul>
              <li>Government funded</li>
              <li>Private Institutes</li>
            </ul>
            <p>
              Government funded ones are affordable than the latter one.
              However, an average cost is 10 k to 55 k $ per student annually.
            </p>

            <h3 className="h2 fw-600 text-green">Upcoming intakes in USA</h3>
            <p>
              Government funded ones are affordable than the latter one.
              However, an average cost is 10 k to 55 k $ per student annually.
            </p>

            <ul>
              <li>
                Fall - Start of intake - September. This is the most popular one
              </li>
              <li>Spring- Start of intake - February</li>
              <li>Summer - Start of intake - May/ June for selected courses</li>
            </ul>

            <h3 className="h2 fw-600 text-green">
              Work authorization for students
            </h3>

            <ul>
              <li>Student must be minimum 18 years of age</li>
              <li>
                International students who study full time can work for 20 hours
                per week.
              </li>
              <li>
                Off-campus employment is possible through written permission by
                USCIS or OISS
              </li>
              <li>
                One must have legal authorization to work and study in USA
              </li>
              <li>Minimum one year of study and F- 1 visa status</li>
            </ul>
            <p>In case of spouse -</p>

            <ul>
              <li>Spouse or dependent can work full time</li>
              <li>Such spouse has to get dependent visa</li>
              <li>These have certain restrictions</li>
            </ul>


            <h1 className="h2 fw-600 text-green">
            USA student visa requirements -
            </h1>
            <p>You will need the following documents for the USA visa application</p>
            <ul>
              <li>Valid passport</li>
              <li>Minimum validity of six months beyond period of stay</li>
              <li>Confirmation of DS- 160</li>
              <li>Recent passport size photograph</li>
              <li>Form I - 20</li>
              <li>Payment of application fees for SEVIS</li>
              <li>Application as non-immigrant</li>
              <li>Other requirements as needed by the USA university or school</li>
            </ul>



            <h1 className="h2 fw-600 text-green">
            Student visa and financial documents needed
            </h1>
            <p>This requires you to submit submission of mandatory proof of academic and financial records. These documents are</p>
            <ul>
             <li>Valid passport</li>
             <li>Minimum validity of six months beyond period of stay</li>
             <li>Print out of online submission of form DS - 160</li>
             <li>Appointment letter for interview (original and Xerox copy)</li>
             <li>Form I - 20 through USA college or university of education</li>
             <li>Confirmation of receipt of payment of Visa fees.</li>
             <li>Payslip</li>
             <li>Original scorecard or mark sheets or provisional certificate of exams like IELTS, GMAT, TOEFL, etc</li>
             <li>Bank statement of minimum 3 years</li>
             <li>This should carry surplus funds for bearing the first year fees (can be of either parent or guardian)</li>
             <li>USA universities have minimum requirement of IELTS score of 6.5 bands or more.</li>
            </ul>






















          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
