// @flow
import * as React from "react";
import virtualojt from "../static/images/virtualojt.jpg";
import Image from "react-bootstrap/Image";
import { MyNav } from "../layouts/MyNav";
import { Footer } from "../layouts/Footer";

export function Virtualojtprogram() {
  return (
    <>
      <MyNav />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Image
              src={virtualojt}
              rounded
              className="img-fluid my-2"
              style={{ width: "100%",maxHeight:"585px",objectFit:"cover" }}
            />
          </div>

          <div className="col-sm-12">
            <h1 className="h2 fw-600 text-green mt-3">
              Virtual OJT Program
            </h1>
            <p>
              The Virtual OJT Program offers students and young professionals an opportunity to gain work experience within an Australian company in a remote professional setting under the supervision of a mentor. Candidates get involved in real projects as assigned by the host organisation, in different fields and industries. A virtual OJT is a great way to start your career, improve your skills, be involved in real projects, and receive mentorship from experienced professionals - all with flexible hours.
            </p>

            <h2 className="h2 fw-600 text-green">PROGRAM DETAILS:</h2>
            <ul>
              <li>OJT opportunities related to your skills and academic background</li>
              <li>6 to 12 weeks, non-paid training positions (10 to 30 hours per week)</li>
              <li>Access to over 7,000 companies Australia wide</li>
              <li>Start every Monday (pending lead time)</li>
              <li>Fields: Marketing, Human Resources, Events Management, IT, Business, Graphic Design, Public Relations, Journalism, and many others</li>
            </ul>

            <h2 className="h2 fw-600 text-green">ELIGIBILITY</h2>
            <ul>
              <li>Aged 18-35</li>
              <li>The OJT field requested is directly related to your educational background</li>
              <li>Currently enrolled in a tertiary course (bachelor degree or above), or</li>
              <li>A recent graduate (bachelor degree or above)</li>
              <li>Upper intermediate – advanced English language skills (equivalent to IELTS 5.5)</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
