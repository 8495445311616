// @flow
import * as React from 'react';
import workInaus from '../static/images/work-in-aus.png';
import Image from "react-bootstrap/Image";
import { MyNav } from '../layouts/MyNav';
import { Footer } from '../layouts/Footer';
export function StudyInAus() {
  return (
    <>
    <MyNav />
     <div className='container'>
        <div className='row'>
            <div className='col-sm-12'>
            <Image
                  src={workInaus}
                  rounded
                  className="img-fluid my-2"
                  style={{ }}
                />
            </div>

            <div className='col-sm-12'>
                <div className='my-3 display-5 fw-600 red'>
                    Why study in Australia?
                </div>
                <p>
                      Australia is undeniably one of the most beautiful places on earth where one dreams to study because of its spectacular various Top universities, incredible nature, vibrant cities, and innumerable oceans. These are just some of the peculiar reasons why many international students' first choice is Australia to study. However, Studying abroad may be slightly daunting as most would relate, but the reward is in terms of education as well as personal development. It is essentially huge in terms of wealth, education, health and quality of life, ranked as one of the best countries. Australia has top-ranked institutions, globally recognized qualifications and an abundance of scholarships when it comes to offering spectacular education.
                </p>

                <h1 className='h2 fw-600 text-green mt-4'>
                    Upcoming intakes in Australia
                </h1>
                <ul>
                    <li>Australia has numerous institutions offering phenomenal education and work opportunities. However, One’s decision will depend on their chosen university and preferred course, one’s past scholastic performance, their test scores, the acceptance rate of the concerned university of choice, opening of application, class commencement, as well as the availability for the course. Some of the factors one can consider are as followed:</li>
                    <li> UG admission</li>
                    <li>Usually begins in February/March</li>
                    <li>Vary according to various institution and courses</li>
                    <li>PG admission</li>
                    <li>Usually commences in February/March</li>
                    <li>May vary according to institution and course</li>
                    <li>Furthermore, Most of the top universities offer master’s courses usually in February.</li>
                </ul>

                <h2 className='h2 fw-600 text-green mt-4'>
                    Work authorization for students in Australia
                </h2>
                <p>
                    Students studying in Australia under a student visa are generally allowed to work up to almost 20 hours a week, with unlimited hours during holidays. If one begins to work before their classes start, or if one exceeds the 20-hour-a-week limit, one’s student visa may be revoked.However, for a student visa, one can ensure to apply online, in person, or by any sort of post.The process for each is slightly varied. Moreover, in order to apply online, one must first contact their education provider electronically to tell them that they have begun their course. Following this, one should allow at least an hour to allow for this information to be transferred into the department’s system. One should then check the charges for an application. Then they ought to complete the online application and follow the prompts it will provide them with.In addition, to apply in person or by post, one must have either a letter from their education provider confirming that they have begun the coursework, or the front of a Form 157P Application for a student visa with permission to work signed and stamped by the authorised education provider. Check the charges for an application. One can even pay by EFTPOS, credit card, money order, or bank check. One must then complete a Form 157P, then mail or deliver their application to the nearest departmental office.
                </p>

                <h3 className='h2 fw-600 text-green mt-4'>
                    Why Study in Australia?
                </h3>
                <p>
                    Documents required for an Australian student visa When applying for your student visa for Australia, you will generally need:        
                </p>

                <ul>
                    <li>Proof of enrolment (your electronic confirmation of enrolment)</li>
                    <li>A valid passport</li>
                    <li>Your visa application fee</li>
                    <li>Your Genuine Temporary Entrant (GTE) statement</li>
                    <li>Academic and work experience documents</li>
                    <li>Evidence of your English proficiency skills (such as IELTS test scores)</li>
                    <li>Evidence of financial ability (tuition fees, living expenses, expenses for dependents, return airfare)</li>
                    <li>For more than two years course, the minimum stay time is 3 years.</li>
                    <li>Details of your health insurance policy (Overseas Student Health Cover)</li>
                    <li>Statement of purpose</li>
                    <li>Passport-size photographs</li>
                </ul>



            </div>
        </div>
     </div>

     <Footer />
    </>
  );
};